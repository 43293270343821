import { CustomVideoPlayer } from "components/ShakaPlayer";
import { Box } from "components/shared/Box";
import { Modal } from "components/shared/Modal";
import { Portal } from "components/shared/Portal";
import { useToggle } from "hooks";
import { LessonDto } from "types";
import htmlParser from "html-react-parser";
import sanitizeHtml from "sanitize-html";

interface LessonBlockProps {
  lessonBlock: LessonDto;
}

export const LessonBlock = ({ lessonBlock }: LessonBlockProps) => {
  const [showVideoModal, toggleTransferForm] = useToggle();

  const lessonData = lessonBlock.translation;

  const handleClick = () => {
    toggleTransferForm();
  };

  const onClose = () => {
    showVideoModal && toggleTransferForm();
  };

  return (
    <>
      <Box
        className="bg-lightBlack cursor-pointer w-full p-0 pb-5 overflow-hidden hover:scale-[0.98] transition-all mt-4"
        onClick={handleClick}
      >
        <img
          className="h-[200px] object-contain w-full"
          src={`https://api.pro-x.app/images/${lessonData.image}`}
          alt=""
        />
        <div className="p-5">
          <h2 className="text-white text-xl">{lessonData.title}</h2>
          <div className="mt-5 [&>p]:overflow-hidden [&>p]:text-ellipsis [&>p]:max-h-[100px]">
            {htmlParser(sanitizeHtml(lessonData.description))}
          </div>
        </div>
      </Box>
      <Portal id="transferModal">
        <Modal
          showCloseButton={false}
          open={showVideoModal}
          handleClose={onClose}
          className="w-full !max-w-[1185px] md:h-full !max-h-[80%] flex items-center justify-center"
          contentClassName="!max-h-[100%]"
        >
          <CustomVideoPlayer
            src={`https://api.pro-x.app/video/${lessonData.videoUrl}`}
          />
        </Modal>
      </Portal>
    </>
  );
};
