import { Box } from "components/shared/Box";
import { InfoIcon } from "components/shared/Icons";

export const WithdrawInstructionExternal = () => (
  <Box className="h-full">
    <div className="flex items-center justify-between mb-5">
      <h3 className="text-lGrey font-semibold ">Note</h3>
      <div className="flex items-center">
        <p className="text-[8px] font-semibold text-grey mr-[6px]">
          Instructions
        </p>
        <InfoIcon />
      </div>
    </div>
    <div className="flex">
      <div className="mr-[10px] [&>svg]:h-[20px] [&>svg]:w-[20px] mt-[2px]">
        <InfoIcon />
      </div>
      <p>
        Please ensure that the receiving platform/wallet supports the token you
        are withdrawing and the network you are using. If you are unsure, please
        contact the receiving platform/wallet before withdrawing funds,
        otherwise your assets may be lost and will not be recovered.
      </p>
    </div>
  </Box>
);
