import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Cookie from "js-cookie";
import { Auth } from "types";

export const LoadingAsAdmin = () => {
  const params = useParams();
  const tokenPayload = params.payload;
  const navigate = useNavigate();
  const token = tokenPayload?.split("&")[0];
  const refreshToken = tokenPayload?.split("&")[1];

  useEffect(() => {
    Cookie.set(Auth.TOKEN, token as string);
    Cookie.set(Auth.REFRESH, refreshToken as string);
    if (Cookie.get(Auth.TOKEN) && Cookie.get(Auth.REFRESH)) navigate("/");
  }, [params, token, refreshToken]);

  return <></>;
};
