import { useMemo } from "react";

interface FilePreviewProps {
  file: File;
  //   handleFileRemove: (selectedFile: File) => void;
}

// handleFileRemove
export const ImagePreview = ({ file }: FilePreviewProps) => {
  const url = useMemo(() => URL.createObjectURL(file), [file]);

  return (
    <div className="h-full w-full">
      <img className="object-cover h-full w-full" src={url} alt="avatar" />
    </div>
  );
};
