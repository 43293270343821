export const SocialAuth = () => {
  const REDIRECT_URI = "/";

  return (
    <>
      <a className="block" href={`https://api.pro-x.app/connect/google`}>
        <div className="flex rounded-lg px-4 py-3 border border-lightGrey cursor-pointer hover:bg-lightGrey transition-all">
          <img src="/icons/googleLogo.svg" alt="FB Logo" />
          <p className="text-white text-sm ml-2">Continue with Google</p>
        </div>
      </a>
    </>
  );
};
