import { useMutation } from "react-query";
import Cookies from "js-cookie";

import { Auth } from "types";
import { clearSession, parseJwt } from "utils";
import { logout } from "requests";

export const useAuth = () => {
  const token = Cookies.get(Auth.TOKEN) ?? "";
  const TwoFactToken = Cookies.get(Auth.TOTP);

  const tokenPayload = parseJwt(token);

  const handleExpiredSession = () => {
    clearSession();
  };

  const { mutate } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      handleExpiredSession();
    },
  });

  const handleSignout = () => {
    mutate({
      token,
    });
  };

  return { tokenPayload, handleSignout, token, TwoFactToken };
};
