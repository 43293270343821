import { useQuery } from "react-query";
// import DatePickerRange from "components/DatePickerRange";
// import { ViewContent } from "components/shared/ViewContent";
import { ReferralShareInformation } from "components/shared/ReferralShareInfo";
import { useUserContext } from "context/user.context";
import { getBalances } from "requests";
import { formatNumber } from "utils";

export const ReferralIncome = () => {
  const { user } = useUserContext();

  const { data: balance } = useQuery({
    queryKey: "balances",
    queryFn: getBalances,
    select: (balance) => {
      const mainBalance = balance.find(({ type }: any) => type === "balance");
      const dividendBalance = balance.find(
        ({ type }: any) => type === "dividend"
      );
      const referralBalance = balance.find(
        ({ type }: any) => type === "referral"
      );

      return {
        balance: mainBalance,
        dividend: dividendBalance,
        referral: referralBalance,
      };
    },
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h5 className="text-base font-bold text-lGrey">Affiliate income</h5>
        {/* <div className="flex items-center">
          <DatePickerRange />
        </div> */}
      </div>
      <div className="mb-5 mt-[6px] flex items-start">
        <span className="text-2xl font-bold text-lGrey">
          {formatNumber("USD", balance?.referral.balance)}{" "}
        </span>
        <span className="ml-2 mt-2 text-xs font-semibold text-lGrey">USD</span>
      </div>
      <div className="mb-5 flex justify-between">
        <div className="flex flex-col md:min-w-[153px]">
          <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
            Total partners
          </h3>
          <p className="font-bold text-lGrey">{user?.referralsCount}</p>
        </div>
        <div className="mx-5 h-[45px] w-[1px] bg-lightBlack" />
        <div className="flex flex-col md:min-w-[153px]">
          <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
            Active partners
          </h3>
          <p className="flex items-center font-bold text-lGrey">
            {user?.activeReferralsCount}
          </p>
        </div>
        {/* <div className="mx-5 h-[45px] w-[1px] bg-lightBlack" /> */}
        {/* <div className="flex flex-col md:min-w-[153px]">
          <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
            New partners
          </h3>
          <p className="font-bold text-lGrey">20</p>
        </div> */}
      </div>
      <ReferralShareInformation />
    </div>
  );
};
