import React, { useState, useEffect } from "react";

interface CountdownTimerProps {
  initialTime: string;
}

export const CountdownTimer = ({ initialTime }: CountdownTimerProps) => {
  const parseTime = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  };

  const formatTime = ({ hours, minutes, seconds }: any) => {
    const pad = (num: any) => String(num).padStart(2, "0");
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  const decrementTime = ({ hours, minutes, seconds }: any) => {
    if (seconds > 0) {
      return { hours, minutes, seconds: seconds - 1 };
    } else if (minutes > 0) {
      return { hours, minutes: minutes - 1, seconds: 59 };
    } else if (hours > 0) {
      return { hours: hours - 1, minutes: 59, seconds: 59 };
    } else {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [time, setTime] = useState(parseTime(initialTime));

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (
          prevTime.hours === 0 &&
          prevTime.minutes === 0 &&
          prevTime.seconds === 0
        ) {
          clearInterval(timerId);
          return prevTime;
        }
        return decrementTime(prevTime);
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return <p>{formatTime(time)}</p>;
};
