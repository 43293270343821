import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  TRANSACTIONS_PER_PAGE,
  paymentOptions,
  withdrawFormValidation,
} from "const";
import { getBalances, getCurrency, withdrawFunds } from "requests";

import { Input } from "components/shared/Input";
import { Button } from "components/shared/Button";
import { useSearchParams } from "react-router-dom";
import { useRef } from "react";
import { FinancialTransactionType } from "types";
import { useToggle } from "hooks";
import { Portal } from "components/shared/Portal";
import { Modal } from "components/shared/Modal";
import { SecuredComponentWith } from "components/shared/SecuredComponentWithModal";
import { toast } from "react-toastify";
import { formatNumber } from "utils";
import { Select } from "components/shared/Select";
import { WhitelistIcon } from "components/shared/Icons";
import { Loader } from "components/shared/Loader";
import { Whitelist } from "components/Whitelist";
import classNames from "classnames";

// custom icon component for react-select
const IconOption = (props: any) => {
  return (
    <div
      className="flex items-center  bg-sidebar p-3 cursor-pointer transition-all hover:bg-lightBlack"
      onClick={() => props.selectOption(props.data)}
    >
      <img
        className="h-[20px] w-[20px]"
        src={props.data.icon}
        alt={props.data.label}
      />
      <p className="ml-5">{props.data.label}</p>
    </div>
  );
};

export const TransferExternalWallet = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const selectRef = useRef(null);

  const [showSecurityModal, toggleSecurityModal] = useToggle();
  const [showWhitelist, toggleWhiteList] = useToggle();

  const currentPage = searchParams.get("page") ?? 1;

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
    reset,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      currency: undefined,
      amount: undefined,
      walletAddress: "",
      addToWhitelist: false,
    },
    resolver: zodResolver(withdrawFormValidation),
    reValidateMode: "onSubmit",
  });

  const {
    data: currencyList,
    isLoading: isCurrencyLoading,
    error: currencyError,
  } = useQuery({
    queryKey: "currency",
    queryFn: getCurrency,
    select: (data) => {
      return data.map(({ code, name }: any) => {
        return {
          label: name,
          value: code,
          // @ts-expect-error
          icon: paymentOptions[code].icon,
          // @ts-expect-error
          hint: paymentOptions[code].hint,
        };
      });
    },
  });

  const {
    data: balances,
    isLoading: isBalancesLoading,
    error: balancesError,
  } = useQuery({
    queryKey: "balances",
    queryFn: getBalances,
    select: (balance) => {
      const mainBalance = balance.find(({ type }: any) => type === "balance");
      const dividendBalance = balance.find(
        ({ type }: any) => type === "dividend"
      );
      const referralBalance = balance.find(
        ({ type }: any) => type === "referral"
      );

      return {
        balance: mainBalance,
        dividend: dividendBalance,
        referral: referralBalance,
      };
    },
  });

  const {
    mutate,
    isLoading,
    error,
    reset: resetMutation,
  } = useMutation({
    mutationFn: withdrawFunds,
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        [
          "withdrawTrans",
          +currentPage,
          TRANSACTIONS_PER_PAGE,
          FinancialTransactionType.WITHDRAW,
        ],
        {
          exact: true,
        }
      );

      queryClient.invalidateQueries("balances", { exact: true });
      toggleSecurityModal();
      // @ts-expect-error
      selectRef.current.clearValue();
      reset();
      toast("Funds successfuly withdrawn");
    },
    onError: () => {
      toast.error("Erro while withdrawing funds");
    },
  });

  watch("currency");

  const onSubmit = (formValues: any) => {
    clearErrors();
    resetMutation();
    toggleSecurityModal();
  };

  const sendRequest = (tokens: any) => {
    const requestBody =
      // @ts-expect-error
      { ...getValues(), currency: getValues("currency")?.value, ...tokens };

    mutate(requestBody);
  };

  if (isBalancesLoading) return <Loader className="!bg-white" />;

  const handleSelectMax = () => {
    setValue("amount", balances?.balance && +balances?.balance.balance);
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            label="Currency"
            options={currencyList}
            control={control}
            name="currency"
            errorMessage={errors.currency?.message}
            optionComponent={IconOption}
          />
          <div>
            <div className="relative">
              <Input
                register={register}
                type="number"
                id="amount"
                placeholder="Enter amount"
                className="mb-[6px] pr-10"
                errorMessage={errors.amount?.message}
                label="Amount"
              />
              <button
                type="button"
                className="text-main absolute right-[13px] top-[45px] text-xs font-semibold"
                onClick={handleSelectMax}
              >
                Max
              </button>
            </div>
            <div className="font-semibold mb-5 text-xs">
              <span className=" text-grey">Available amount:</span>
              <span className=" text-lGrey ml-[6px]">
                {formatNumber("USD", balances?.balance.balance)} USD
              </span>
            </div>
          </div>

          <div>
            <div className="relative">
              <Input
                register={register}
                type="text"
                id="walletAddress"
                placeholder="Enter wallet address"
                className="mb-[6px] pr-10"
                errorMessage={errors.walletAddress?.message}
                label="Your Wallet Address"
              />
              {/* <div
                className={classNames(
                  "absolute right-[3px] top-[31px] rounded-full p-3 cursor-pointer",
                  showWhitelist && "bg-main"
                )}
                onClick={toggleWhiteList}
              >
                <WhitelistIcon />
              </div> */}
              {/* {showWhitelist && (
                <Whitelist onClick={(item) => console.log(item)} />
              )} */}
            </div>
            {/* <AddToWhitelist
              register={register}
              id="addToWhitelist"
              checked={getValues("addToWhitelist")}
              watch={watch}
            /> */}
          </div>
          <Button
            text="Withdraw"
            type="submit"
            className="bg-main font-semibold text-black mt-4"
            isLoading={isLoading}
          />
        </form>
      </div>
      <Portal id="securityModal">
        <Modal open={showSecurityModal} handleClose={toggleSecurityModal}>
          <SecuredComponentWith
            isLoading={isLoading}
            error={error}
            sendRequest={sendRequest}
          />
        </Modal>
      </Portal>
    </>
  );
};
