export const paymentOptions = {
  // BTC: {
  //   icon: "/icons/Bitcoin.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // LTC: {
  //   icon: "/icons/Litecoin.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // BNB: {
  //   icon: "/icons/BNB.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // BUSD: {
  //   icon: "/icons/BUSD.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // DOGE: {
  //   icon: "/icons/DOGE.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // ETC: {
  //   icon: "/icons/ETC.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // ETH: {
  //   icon: "/icons/ETH.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // SHIB: {
  //   icon: "/icons/SHIB.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  // TRX: {
  //   icon: "/icons/TRX.svg",
  //   hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  // },
  USD: {
    icon: "/icons/USDC.svg",
    hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  },
  USDT: {
    icon: "/icons/USDT.svg",
    hint: "In order make payment, select your currency and enter your amount, after that you'll see QRCode and wallet address",
  },
};

export const TRANSACTIONS_PER_PAGE = 10;
