import { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

interface ModalProps {
  open: boolean;
  className?: string;
  handleClose: () => void;
  showCloseButton?: boolean;
  contentClassName?: string;
}

// TODO: fix modal height for small macbooks sizes
export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  className,
  contentClassName,
  open,
  handleClose,
  showCloseButton = true,
}) => {
  return (
    <AnimatePresence>
      {open && (
        <>
          <motion.div
            className={classNames(
              "fixed bg-lightBlack md:max-w-[580px] m-auto z-[51] rounded-lg p-5 left-1/2 -translate-x-1/2 -translate-y-1/2",
              className && className
            )}
            key="modal"
            initial={{ top: "30%", opacity: 0 }}
            animate={{ top: "50%", opacity: 1 }}
            exit={{ top: "20%", opacity: 0 }}
          >
            {showCloseButton && (
              <div
                onClick={handleClose}
                className="absolute right-5 top-5 z-20"
              >
                <div className="cursor-pointer">
                  <img src="/icons/close.svg" alt="close icon" />
                </div>
              </div>
            )}
            <div
              className={classNames(
                "w-full max-h-[400px] md:max-h-full overflow-x-auto",
                contentClassName && contentClassName
              )}
            >
              {children}
            </div>
          </motion.div>
          <motion.div
            onClick={handleClose}
            className={classNames(
              "fixed top-0 left-0 bottom-0 right-0 bg-[#212121] opacity-100 z-50"
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
          />
        </>
      )}
    </AnimatePresence>
  );
};
