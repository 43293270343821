import { Loader } from "components/shared/Loader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { getSessions, removeSession } from "requests";
import { RecentVisitSessionProps } from "types";
import { Box } from "components/shared/Box";
import { AndroidIcon, AppleIcon, WindowsIcon } from "components/shared/Icons";

export const RecentLogins = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: "recentSessions",
    queryFn: getSessions,
  });

  const { mutate: deleteSession } = useMutation({
    mutationFn: removeSession,
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries("recentSessions", { exact: true });
      toast(message);
    },
  });

  const handleRemoveAllSessionsExceptCurrent = () => {
    deleteSession({
      sessionId: 0,
    });
  };

  // THANKS CHATGPT
  function extractOS(userAgentString: string) {
    const osRegex = /\(([^)]+)\)/;
    const match = userAgentString.match(osRegex);

    if (match && match[1]) {
      const platformDetails = match[1].split(";");
      for (let detail of platformDetails) {
        if (detail.includes("Mac OS")) {
          return detail.trim();
        }
      }
    }
    return "OS not identified";
  }

  const getSessionInfo = (userAgent: string) => {
    if (userAgent.includes("Macintosh"))
      return {
        icon: <AppleIcon />,
        OS: "Apple Macintosh",
        agent: extractOS(userAgent),
      };
    if (userAgent.includes("iPhone"))
      return {
        icon: <AppleIcon />,
        OS: "Apple IPhone",
        agent: extractOS(userAgent),
      };
    if (userAgent.includes("Windows"))
      return {
        icon: <WindowsIcon />,
        OS: "Windows",
        agent: extractOS(userAgent),
      };

    return {
      icon: <AndroidIcon />,
      OS: "Android",
      agent: extractOS(userAgent),
    };
  };

  const renderSessionsView = () => {
    if (isLoading) return <Loader className="!bg-white" />;

    if (error) return <div>Error while loading sessions</div>;

    return (
      <ul className="flex flex-col w-full ">
        {data.map((session: RecentVisitSessionProps) => {
          return (
            <li
              key={session.sessionId}
              className="border-b border-lightBlack items-center justify-between py-[10px] flex first:pt-0"
            >
              <div className="flex">
                {getSessionInfo(session.userAgent).icon}
                <div className="ml-[10px] flex flex-col">
                  <h4 className="text-main text-sm font-semibold">
                    {getSessionInfo(session.userAgent).OS}
                  </h4>
                  <p className="text-grey text-xs">
                    {getSessionInfo(session.userAgent).agent}
                  </p>
                </div>
              </div>
              <div>
                <div className="text-lGrey text-sm text-right">
                  {session.ipAddress}
                </div>
                <div className="text-grey text-xs text-right">
                  {dayjs(session.lastActivityAt).format("YYYY-MM-DD h:mm A")}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div>
      <div className="flex items-center flex-wrap justify-between pb-4">
        <div>
          <h4 className="text-lGrey font-semibold">Recent Logins</h4>
          <p className="text-xs text-grey">Manage your devices</p>
        </div>
        <button
          type="button"
          onClick={handleRemoveAllSessionsExceptCurrent}
          className="py-[6px] px-[22px] rounded-xl bg-boxBg text-lGrey text-sm font-semibold"
        >
          Sign Out from All
        </button>
      </div>

      <Box className="max-h-[200px]  overflow-y-auto bg-lBlack border-lightBorder">
        {renderSessionsView()}
      </Box>
    </div>
  );
};
