import { ChangeEvent, useState } from "react";
import {
  SetFieldValue,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

interface UseFileUpload {
  // TODO: remove any after demo.
  // OR use useFormContext in order to get them other way
  setValue: SetFieldValue<any>;
  fieldId: string;
  setError?: UseFormSetError<any>;
  clearErrors?: UseFormClearErrors<any>;
  single?: boolean;
  onFileUploadCb?: (files: any[]) => void;
}

export const useFileUpload = ({
  setValue,
  fieldId,
  setError,
  clearErrors,
  single = true,
  onFileUploadCb,
}: UseFileUpload) => {
  const [files, setFiles] = useState<File[]>([]);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error
    const file = e.target.files[0];

    if (!file.type.includes("image")) {
      setError &&
        setError(fieldId as string, {
          type: "412",
          message: "The file should be image",
        });
      return;
    }

    if (file && file.size >= 2000000) {
      setError &&
        setError(fieldId as string, {
          type: "412",
          message: "The selected image is too large",
        });
      return;
    }

    // clean errors on new file
    clearErrors && clearErrors(fieldId);

    setFiles((prevState) => {
      if (prevState.find((item) => file && item.name === file.name))
        return [...prevState];

      if (single) {
        setFiles([file]);
        const updatedValue = [file];

        fieldId && setValue(fieldId, updatedValue);
        // @ts-expect-error
        e.target.value = null;

        onFileUploadCb && onFileUploadCb(updatedValue);

        return updatedValue;
      } else {
        const updatedValue = [...prevState, file].filter((file) => file);

        fieldId && setValue(fieldId, updatedValue);
        // @ts-expect-error
        e.target.value = null;
        return updatedValue;
      }
    });
  };

  const handleFileRemove = (selectedFile: File) => {
    const filteredFiles = [...files].filter(
      (file) => file && file.name !== selectedFile.name
    );

    setFiles(filteredFiles);
    fieldId && setValue(fieldId, filteredFiles);
  };

  return {
    files,
    handleFileUpload,
    handleFileRemove,
    // in case if you want to set files implicitly
    setFiles,
  };
};
