import { Box } from "components/shared/Box";
import { useParams } from "react-router";
import htmlParser from "html-react-parser";
import { Gallery } from "react-photoswipe-gallery";
import sanitizeHtml from "sanitize-html";

import {
  cancelTransaction,
  getLanguages,
  getNextLesson,
  getSingleCourse,
  getSingleLesson,
} from "requests";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ViewContent } from "components/shared/ViewContent";
import { LessonBlock } from "components/LessonBlock";
import { CustomVideoPlayer } from "components/ShakaPlayer";
import { Breadcrumbs } from "components/shared/Breadcrumbs";
import { CountdownTimer } from "components/CountdownTimer";
import { CourseDto, LanguageProps } from "types";
import { toast } from "react-toastify";
import { Button } from "components/shared/Button";
import { Select } from "components/shared/Select";
import { useForm } from "react-hook-form";
import { useAuth } from "hooks";

export const CourseDetails = () => {
  const { token } = useAuth();
  const { control, getValues, watch } = useForm({
    defaultValues: {
      languageId: {
        label: "English",
        value: 1,
      },
    },
  });

  // const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();

  watch("languageId");

  const {
    data: course,
    isLoading: isCoursesLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["courses", params.courseId, getValues("languageId").value],
    queryFn: getSingleCourse,
  });

  const { isLoading: isLanguagesLoading, data: languages } = useQuery({
    queryKey: "languages",
    queryFn: getLanguages,
    select: (data: any) =>
      data.map((d: LanguageProps) => ({
        label: d.name,
        value: d.id,
      })),
  });

  const {
    data: lesson,
    isLoading: isLessonLoading,
    isError: lessonError,
  } = useQuery({
    queryKey: ["lesson", params.courseId, getValues("languageId").value],
    queryFn: getSingleLesson,
  });

  const {
    data: nextLessonAvailable,
    isLoading: isNextLessonAvailableLoading,
    isError: isNextLessonError,
  } = useQuery({
    queryKey: ["nextLesson", params.courseId],
    queryFn: getNextLesson,
  });

  const { mutate: mutateCancelTransaction, isLoading: isCancelling } =
    useMutation({
      mutationFn: cancelTransaction,
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          ["courses", params.courseId, getValues("languageId").value],
          { exact: true }
        );
        toast.success("Course purchase was cancelled");
        // setShowConfetti(true);
      },
      onError: () => {
        toast.error("Error while purchasing course");
      },
    });

  // pizdec ebanii refactor.
  const renderNextLessonBlock = () => {
    if (isNextLessonAvailableLoading) return <></>;

    if (isNextLessonError) {
      console.error(isNextLessonError, "isNextLessonError");
      return <></>;
    }

    return nextLessonAvailable.nextLessonUnlockDate === "00:00:00" ||
      !nextLessonAvailable.nextLessonUnlockDate ? (
      <></>
    ) : (
      <div className="mb-5 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3">
        <p>Next lesson will be available after:</p>
        <CountdownTimer
          initialTime={nextLessonAvailable.nextLessonUnlockDate}
        />
      </div>
    );
  };

  const breadcrumbsCourseDetails = [
    {
      title: "Courses",
      href: "/courses",
    },
    {
      title: `Course: ${course?.translation.title}`,
      href: `/courses/${course?.id}`,
    },
  ];

  const cancelPayment = () => {
    mutateCancelTransaction(course?.pendingTransactionId);
  };

  const checkTransStatus = () => {
    refetch();
  };

  const renderButtonLogic = () => {
    if (course?.hasPendingTransaction) {
      return (
        <>
          <Button
            type="button"
            className="bg-main mt-4"
            onClick={() => checkTransStatus()}
          >
            Refresh status
          </Button>
          <Button
            type="button"
            className="mt-4 hover:bg-main"
            onClick={() => cancelPayment()}
            disabled={isCancelling}
          >
            Cancel payment
          </Button>
        </>
      );
    }

    if (!course?.isPurchased)
      return (
        <Button
          type="button"
          className="bg-main"
          onClick={() => {
            // mutateBuyCourse({ courseId: course?.id });
            window.location.replace(
              `https://api.pro-x.app/buy-course/${course?.id}?token=${token}`
            );
          }}
        >
          Buy course for ${course?.price}
        </Button>
      );
  };

  return (
    <>
      {(!isCoursesLoading || isFetching) && (
        <Breadcrumbs links={breadcrumbsCourseDetails} />
      )}
      <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
        <Box className="col-span-2 md:col-span-3">
          <div className="flex flex-col items-start justify-between">
            <div className="flex items-center justify-between w-full flex-col md:flex-row">
              <h3 className="mb-2 font-bold text-lGrey text-3xl">
                Course details
              </h3>

              {!isLanguagesLoading && (
                <Select
                  options={languages}
                  control={control}
                  name="languageId"
                  placeholder="Select Language"
                />
              )}
            </div>

            <ViewContent
              isLoading={isCoursesLoading || isFetching}
              isError={isError}
              data={course}
            >
              {(courseDetails: CourseDto) => {
                const courseData = courseDetails.translation;
                return (
                  <div className="mt-10 w-full">
                    {courseData.videoPreview && (
                      <>
                        <CustomVideoPlayer
                          src={`https://api.pro-x.app/video/${courseData.videoPreview}`}
                        />
                      </>
                    )}
                    <h3 className="text-white text-3xl mt-5">
                      {courseData.title}
                    </h3>
                    <div className="mt-5">
                      {/* <h3 className="text-3xl text-white">Lesson description:</h3> */}
                      <div>
                        {htmlParser(sanitizeHtml(courseData.description))}
                      </div>
                    </div>
                  </div>
                );
              }}
            </ViewContent>
          </div>
        </Box>
        {!isCoursesLoading && (
          <>
            {course?.isPurchased ? (
              <Box className="w-full col-span-1 md:col-span-1">
                <h3 className="text-white text-3xl mb-5">
                  Lessons for this course
                </h3>

                {renderNextLessonBlock()}

                <div className="flex flex-col max-h-[900px] overflow-y-auto">
                  <Gallery>
                    <ViewContent
                      isLoading={isLessonLoading}
                      isError={lessonError}
                      data={lesson}
                    >
                      {(data: any) => {
                        return data.map((lessonBlock: any) => (
                          <LessonBlock
                            key={lessonBlock.id}
                            lessonBlock={lessonBlock}
                          />
                        ));
                      }}
                    </ViewContent>
                  </Gallery>
                </div>
              </Box>
            ) : (
              <Box className="w-full col-span-1 md:col-span-1 ">
                <h2 className="text-center mb-4 text-white">
                  To watch lessons:
                </h2>
                {renderButtonLogic()}
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
};
