import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import Cookies from "js-cookie";

import { Button } from "components/shared/Button";
import { Input } from "components/shared/Input";
import { useAuth } from "hooks";
import { check2fa } from "requests";
import {
  FaCodeSecurityScreenValidationSchema,
  faCodeSecurityScreenValidation,
} from "const";
import { Auth, ResponseError } from "types";

const FASecurityScreen = () => {
  const { TwoFactToken } = useAuth();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: check2fa,
    onSuccess: ({ token, refreshToken }) => {
      Cookies.set(Auth.TOKEN, token);
      Cookies.set(Auth.REFRESH, refreshToken);
      window.location.reload();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FaCodeSecurityScreenValidationSchema>({
    defaultValues: {
      "2faToken": TwoFactToken,
      totp: "",
    },
    resolver: zodResolver(faCodeSecurityScreenValidation),
  });

  const onSubmit = (formValues: any) => {
    mutate(formValues);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-sidebar">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center justify-center"
      >
        <h2 className="text-white mb-5">
          Enter your 2FA code from Authentication application
        </h2>
        <Input
          id="totp"
          register={register}
          type="number"
          errorMessage={errors.totp?.message}
          className="mb-5"
        />
        {error && (error as ResponseError)?.response?.data.error ? (
          <div className="text-center text-red mb-5">
            {(error as ResponseError)?.response?.data.error}
          </div>
        ) : null}
        <Button
          type="submit"
          text="Submit"
          className="bg-main font-semibold text-black"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default FASecurityScreen;
