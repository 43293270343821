import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";

import { ForgotPasswordValidaionSchema, forgotPasswordValidation } from "const";

import { forgotPasswordNew } from "requests";
import { ROUTES } from "config/routes";

import { Button } from "components/shared/Button";
import { Input } from "components/shared/Input";
import { PuzzleCaptcha } from "components/PuzzleCaptcha";
import { ResponseError } from "types";

import "react-phone-number-input/style.css";
import { errorParser } from "utils";

const ForgotPasswordNew = () => {
  const navigate = useNavigate();
  const params = useParams();

  const token = params.code ?? "";

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaPassed, setCaptchaPassed] = useState(false);

  const { error, isError, isLoading, mutate, data } = useMutation({
    mutationFn: forgotPasswordNew,
    onSuccess: () => {
      navigate(ROUTES.login);
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ForgotPasswordValidaionSchema>({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    resolver: zodResolver(forgotPasswordValidation),
  });

  const onSubmit = async (formValues: ForgotPasswordValidaionSchema) => {
    if (!showCaptcha && !captchaPassed) {
      setShowCaptcha(true);
    } else {
      mutate({ formValues, token });
    }
  };

  return (
    <div>
      <a
        href="https://pro-x.app/"
        rel="noreferrer"
        target="_blank"
        className="text-white text-3xl text-center block mx-auto mb-10"
      >
        <span className="text-main">PRO</span>X
      </a>
      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Journey Today
      </h3>

      <div className="max-w-sm [&>*]:mb-4 m-auto md:min-w-[360px]">
        <form className="[&>*]:w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="h-[38px] rounded-md px-4 mb-4"
            type="password"
            register={register}
            id="password"
            placeholder="Password"
            errorMessage={errors.password?.message}
          />
          <div className="pl-[30px] mb-4">
            <ol className="list-disc text-[10px]">
              <li className="text-placeholder">
                Upper case letters: from A to Z
              </li>
              <li className="text-placeholder">
                Lower case letters: from a to z
              </li>
              <li className="text-placeholder">Numbers: from 0 to 9</li>
              <li className="text-placeholder">
                Special symbols: {`$ & + , : ; = ? @ # | ' < > . ^ * ( ) % ! -`}
              </li>
            </ol>
          </div>

          <Input
            className="h-[38px] rounded-md px-4 mb-4"
            type="password"
            register={register}
            id="password_confirmation"
            placeholder="Confirm your password"
            errorMessage={errors.password_confirmation?.message}
          />

          {showCaptcha && (
            <PuzzleCaptcha
              onVerifyCallback={() => {
                setCaptchaPassed(true);
                mutate({ formValues: getValues(), token });
              }}
            />
          )}

          {isError && (error as ResponseError)?.response?.data.error && (
            <div className="text-center text-red mt-5">
              {errorParser(error as ResponseError)}
              {/* {(error as ResponseError)?.response?.data.error} */}
            </div>
          )}
          {data && (
            <div className="text-center text-main mt-5">{data.message}</div>
          )}
          <Button
            isLoading={isLoading}
            type="submit"
            text="Change password"
            className="bg-main text-sm mt-5 font-bold"
          />
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordNew;
