import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";

import { forgotPasswordEmail } from "requests";
import {
  ForgotPasswordlEmailValidationSchema,
  forgotPasswordEmailValidation,
} from "const";

import { ResponseError } from "types";
import { Input } from "components/shared/Input";
import { Button } from "components/shared/Button";
import { errorParser } from "utils";

const ForgotPasswordEmail = () => {
  const { error, isError, isLoading, mutate, data } = useMutation({
    mutationFn: forgotPasswordEmail,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordlEmailValidationSchema>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(forgotPasswordEmailValidation),
  });

  const onSubmit = async (formValues: ForgotPasswordlEmailValidationSchema) => {
    mutate(formValues);
  };

  return (
    <div>
      <a
        href="https://pro-x.app/"
        rel="noreferrer"
        target="_blank"
        className="text-white text-3xl text-center block mx-auto mb-10"
      >
        <span className="text-main">PRO</span>X
      </a>
      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Journey Today Today
      </h3>
      <p className="text-[#B8B8B8] mb-6 w-[305px] text-center m-auto">
        Enter your email for password reset
      </p>
      <form
        className="m-auto max-w-[360px] mb-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          placeholder="Email"
          id="email"
          register={register}
          className="h-[38px] mb-4 rounded-md px-4 w-full"
          errorMessage={errors.email?.message}
        />

        {isError && (error as ResponseError)?.response?.data.error && (
          <div className="text-center text-red mt-5">
            {errorParser(error as ResponseError)}
          </div>
        )}
        {data && (
          <div className="text-center text-main mt-5">{data.message}</div>
        )}
        <Button
          type="submit"
          text="Sent email"
          className="bg-main w-[360px]  py-0 mt-[57px] font-bold"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default ForgotPasswordEmail;
