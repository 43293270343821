import { FC } from "react";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { changeProfilePassword } from "requests";
import { forgotPasswordValidation } from "const";
import { ResponseError, WithPinProps } from "types";
import { errorParser } from "utils";

import { Button } from "components/shared/Button";
import { Input } from "components/shared/Input";
import { toast } from "react-toastify";

interface ChangePasswordModalProps extends WithPinProps {
  toggleModal: () => void;
}

export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  toggleModal,
  pin,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    resolver: zodResolver(forgotPasswordValidation),
  });

  const { error, isError, isLoading, mutate, data } = useMutation({
    mutationFn: changeProfilePassword,
    onSuccess: ({ message }) => {
      toggleModal();
      toast(message);
      //   navigate(ROUTES.login);
    },
  });

  const onSubmit = (formValues: any) => {
    mutate({ ...formValues, pin });
  };

  return (
    <div className="w-[320px] md:w-[380px] m-auto ">
      <h3 className="text-lGrey text-base font-semibold mb-[6px]">
        Change Password
      </h3>
      <h5 className="text-grey text-xs">Change Your account password</h5>
      <form className=" mt-5" onSubmit={handleSubmit(onSubmit)}>
        <Input
          className="mb-4"
          type="password"
          register={register}
          id="password"
          placeholder="Password"
          errorMessage={errors.password?.message}
          label="Password"
        />
        {/* <div className="pl-[30px] mb-4">
          <ol className="list-disc text-[10px]">
            <li className="text-placeholder">
              Upper case letters: from A to Z
            </li>
            <li className="text-placeholder">
              Lower case letters: from a to z
            </li>
            <li className="text-placeholder">Numbers: from 0 to 9</li>
            <li className="text-placeholder">
              Special symbols: {`$ & + , : ; = ? @ # | ' < > . ^ * ( ) % ! -`}
            </li>
          </ol>
        </div> */}

        <Input
          className="mb-4"
          type="password"
          register={register}
          id="password_confirmation"
          placeholder="● ● ● ● ● ● ●"
          errorMessage={errors.password_confirmation?.message}
          label="Confirm your password"
        />

        {isError && (error as ResponseError)?.response?.data.error && (
          <div className="text-center text-red mt-5">
            {errorParser(error as ResponseError)}
            {/* {(error as ResponseError)?.response?.data.error} */}
          </div>
        )}
        {data && (
          <div className="text-center text-main mt-5">{data.message}</div>
        )}
        <p className="text-grey text-xs font-semibold">
          Use 8 or more characters with a mix of letters, numbers and symbols
        </p>
        <Button
          isLoading={isLoading}
          type="submit"
          text="Apply"
          className="bg-main mt-5"
        />
      </form>
    </div>
  );
};
