import { ViewContent } from "components/shared/ViewContent";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getLatestReferrals } from "requests";

export const TopReferrals = () => {
  const {
    data: latestReferrals,
    isLoading,
    isError,
  } = useQuery({
    queryKey: "latestReferrals",
    queryFn: getLatestReferrals,
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h5 className="text-lGrey font-bold text-base">
          Latest registered partners
        </h5>
      </div>
      <ViewContent
        isError={isError}
        isLoading={isLoading}
        data={latestReferrals}
      >
        {(data: any) => {
          return (
            <ul className="max-h-[230px] overflow-y-auto pr-5 w-full ">
              {data?.map((latestReferral: any, i: number) => (
                <li
                  key={i}
                  className="py-[10px] border-b border-lightBlack grid grid-cols-1 md:grid-cols-3"
                >
                  <div>
                    <p className="text-sm text-lGrey font-semibold">
                      {latestReferral.username ?? "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-lGrey font-semibold">
                      {latestReferral.email}
                    </p>
                  </div>
                  <div className=" text-white text-right">
                    {dayjs(latestReferral.createdAt).format("DD/MM/YYYY")}
                  </div>
                </li>
              ))}
            </ul>
          );
        }}
      </ViewContent>
    </div>
  );
};
