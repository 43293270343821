import classNames from "classnames";
import { PinCodeForm } from "components/PinCode";
import { Box } from "components/shared/Box";
import { PinIcon } from "components/shared/Icons";
import { Modal } from "components/shared/Modal";
import { Portal } from "components/shared/Portal";
import { SecuredWithPin } from "components/shared/SecuredWithPin";
import { useUserContext } from "context/user.context";
import { useToggle } from "hooks";

export const PinSection = () => {
  const [isPinModalOpen, togglePinModal] = useToggle();
  const { user } = useUserContext();

  return (
    <>
      <Box
        className={classNames("bg-lightBlack mt-5", !user?.pin && "border-red")}
      >
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex">
            <PinIcon />
            <div className="flex flex-col ml-[10px]">
              <h4 className=" text-lGrey text-base font-semibold">
                PIN{" "}
                {!user?.pin ? (
                  <span className="text-red text-sm ml-[10px]">Required</span>
                ) : (
                  <span className="text-success text-sm ml-[10px]">
                    Enabled
                  </span>
                )}
              </h4>
              <p className="text-grey text-xs font-semibold">
                Pin is required in order to make financial transactions
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 ml-auto">
            <button
              type="button"
              className={classNames(
                "bg-dMain border-main text-main rounded-xl border  w-[155px] py-[4px]"
              )}
              onClick={togglePinModal}
            >
              {user?.pin ? "Change" : "Set Up"}
            </button>
          </div>
        </div>
      </Box>
      <Portal id="pinModal">
        <Modal open={isPinModalOpen} handleClose={togglePinModal}>
          {user?.pin ? (
            <SecuredWithPin>
              <PinCodeForm togglePinModal={togglePinModal} />
            </SecuredWithPin>
          ) : (
            <PinCodeForm togglePinModal={togglePinModal} />
          )}
        </Modal>
      </Portal>
    </>
  );
};
