import ReactSelect, { StylesConfig } from "react-select";
import {
  Control,
  Controller,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";

import { Option } from "types";

interface SelectProps<TForm extends FieldValues>
  extends UseControllerProps<TForm> {
  options: readonly Option[];
  label?: string;
  isLoading?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  control: Control<TForm>;
  isClearable?: boolean;
  placeholder?: string;
  optionComponent?: any;
}

const styles = (
  error: string | undefined
): StylesConfig<{ label: string; name: string }> => {
  const themeStyles = {
    background: "rgba(24, 26, 32, 0.35)",
    borderColor: "rgba(133, 142, 155, 0.35)",
    color: "#fff",
    hover: "#848484",
    indicator: "#EAECEF",
    input: "#fff",
  };

  return {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      borderColor: error ? "#FF1212" : themeStyles.borderColor,
      minHeight: 54,
      background: themeStyles.background,
      // width: 360,
      paddingLeft: 8,
      outline: "none",
      hover: {
        borderColor: themeStyles.borderColor,
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      borderRadius: "12px",
      minHeight: 44,
    }),
    input: (styles) => ({
      ...styles,
      borderRadius: "12px",
      height: 30,
      color: themeStyles.input,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: themeStyles.indicator,
      hover: {
        color: "#fff",
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    menu: (styles) => ({
      ...styles,
      background: "#2C3138",
      color: themeStyles.color,
      // width: 360,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: themeStyles.color,
    }),
    option: (styles, state) => ({
      ...styles,
      color: themeStyles.color,
      backgroundColor: state.isFocused
        ? themeStyles.hover
        : themeStyles.background,
    }),
  };
};

export function Select<TForm extends FieldValues>({
  options = [],
  label,
  isLoading = false,
  errorMessage,
  control,
  name,
  isClearable,
  disabled,
  placeholder = "Select",
  optionComponent,
}: SelectProps<TForm>) {
  return (
    <div className="flex flex-col mb-4">
      {label && (
        <label className="text-sm text-lGrey mb-[6px] cursor-pointer">
          {label}
        </label>
      )}

      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <ReactSelect
              {...field}
              name={name}
              // @ts-expect-error
              options={options}
              isLoading={isLoading}
              styles={styles(errorMessage)}
              maxMenuHeight={130}
              isDisabled={disabled}
              placeholder={placeholder}
              isClearable={isClearable}
              components={optionComponent ? { Option: optionComponent } : {}}
            />
          );
        }}
      />
      {errorMessage && (
        <div className="text-red text-[12px] mt-[6px]">{errorMessage}</div>
      )}
    </div>
  );
}
