import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form";

import { useUserContext } from "context/user.context";
import { GenderTypes, Option, UserProps } from "types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFileUpload } from "hooks";

import { ImagePreview } from "components/shared/ImagePreview";
import { Input } from "components/shared/Input";
import { Select } from "components/shared/Select";
import { RecentLogins } from "components/RecentLogins";
import { PinSection } from "components/PinSection";
import { TwoFactorSection } from "components/2FASection";
import { PasswordSection } from "components/PasswordSection";

import { profileInfoValidation } from "const";
import { Box } from "components/shared/Box";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Button } from "components/shared/Button";

const genderList = [
  {
    label: "Male",
    value: GenderTypes.MALE,
  },
  {
    label: "Female",
    value: GenderTypes.FEMALE,
  },
];

const Settings = () => {
  const {
    user,
    updateUser,
    isUserUpdating,
    mutateUserAvatar,
    isUserAvatarUpdating,
  } = useUserContext();

  const userGender = !user?.gender
    ? null
    : genderList.find((gender) => gender.value === +user?.gender);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    control,
    watch,
  } = useForm<UserProps>({
    defaultValues: {
      ...user,
      gender: userGender,
      telegram: user?.telegram ?? "",
    } as UserProps,
    resolver: zodResolver(profileInfoValidation),
  });

  watch("gender");

  const { handleFileUpload, files, setFiles } = useFileUpload({
    setValue,
    fieldId: "avatar",
    setError,
    clearErrors,
    onFileUploadCb: (files) => {
      try {
        mutateUserAvatar({
          avatarFile: files[0],
        });
      } catch (error) {
        toast.error("Error while uploading avatar");
      } finally {
        setFiles([]);
      }
    },
  });

  const onSubmit = (formValues: UserProps) => {
    let userObj: any = {};

    if (formValues.telegram) {
      userObj.telegram = formValues.telegram;
    }

    updateUser({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phone: formValues.phone,
      gender: (formValues.gender as Option).value,
      ...userObj,
    });
  };

  const renderAvatarPreview = () => {
    if (typeof getValues().avatar !== "string" && getValues().avatar?.length) {
      // @ts-expect-error
      return <ImagePreview file={getValues().avatar[0]} />;
    }

    if (!!files.length) {
      return <ImagePreview file={files[0]} />;
    }

    if (typeof getValues().avatar === "string") {
      return (
        <img
          className="object-cover h-full w-full"
          src={getValues().avatar as string}
          alt="avatar"
        />
      );
    }

    return <img src="/images/defaultUserAvatar.jpeg" alt="avatar" />;
  };

  return (
    <>
      <Box>
        <h4 className="text-lGrey font-bold mb-5">General</h4>

        <Box className="border-none bg-lightBlack">
          <div className="flex items-center mb-4 flex-col md:flex-row">
            <div className="h-[120px] w-[120px] rounded-[40px] overflow-hidden mb-5 md:mb-0 md:mr-5">
              {renderAvatarPreview()}
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="avatar"
                className={classNames(
                  "block py-[6px] px-4 bg-boxBg rounded-lg text-lGrey cursor-pointer text-center",
                  isUserAvatarUpdating && "pointer-events-none"
                )}
              >
                Change avatar
                <input
                  hidden
                  type="file"
                  name="avatar"
                  id="avatar"
                  accept=".png, .jpg"
                  onChange={handleFileUpload}
                />
              </label>
              <div className="mt-[6px] text-grey text-xs font-semibold">
                JPG or PNG, 2 MB max file size
              </div>
            </div>
            {errors.avatar?.message && (
              <div className="text-center text-red mb-3">
                {errors.avatar?.message}
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-10 md:mt-0">
            <div className="flex flex-col">
              <label className="text-sm text-lGrey mb-[6px]">Login</label>
              <div className="py-[14px] px-4 text-grey flex items-center justify-between border border-lightBorder rounded-xl">
                <input
                  value={getValues("login")}
                  disabled
                  className="w-full pr-4"
                />
                <a
                  className="text-main text-xs font-semibold"
                  href="https://t.me/JulliAi"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </div>
              <p className="mb-5 mt-[10px] text-grey text-xs font-semibold">
                The change is available through technical support
              </p>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-lGrey mb-[6px]">E-mail</label>
              <div className="py-[14px] px-4 text-grey flex items-center justify-between border border-lightBorder rounded-xl">
                <input value={user?.email} disabled className="w-full pr-4" />
                <a
                  className="text-main text-xs font-semibold"
                  href="https://t.me/JulliAi"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </div>
              <p className="mb-5 mt-[10px] text-grey text-xs font-semibold">
                The change is available through technical support
              </p>
            </div>
            <Input
              type="text"
              id="firstName"
              register={register}
              placeholder="First name"
              errorMessage={errors.firstName?.message}
              label="First name"
              description="Changes are available to you"
            />
            <Input
              type="text"
              id="lastName"
              register={register}
              placeholder="Last name"
              errorMessage={errors.lastName?.message}
              label="Last name"
              description="Changes are available to you"
            />
            <Select
              control={control}
              options={genderList}
              name="gender"
              errorMessage={errors.gender?.message}
              label="Gender"
            />
            <div className="flex flex-col">
              <label className="text-sm text-lGrey mb-[6px]">
                Phone number
              </label>
              <PhoneInput
                name="phone"
                control={control}
                placeholder="+123456789"
                // countrySelectComponent={<div></div>}
                className="w-full text-sm rounded-xl border border-lightBorder p-4 bg-lBlack outline-none focus:border-secondaryButton transition-all text-lGrey placeholder:text-placeholder placeholder:text-sm disabled:text-lightGrey"
              />
              <p className="mb-5 mt-[10px] text-grey text-xs font-semibold">
                2FA cannot be disabled (possibly through personal{" "}
                <a
                  className="text-main text-xs font-semibold"
                  href="https://t.me/JulliAi"
                  target="_blank"
                  rel="noreferrer"
                >
                  managers
                </a>
                , but only as a last resort)
              </p>
            </div>
            <Input
              type="text"
              id="telegram"
              register={register}
              placeholder="Telegram"
              errorMessage={errors.telegram?.message}
              description="Changes are available to you"
              label="Telegram"
            />
            <Button
              text="Save Changes"
              type="submit"
              className=" bg-main font-semibold text-sm "
              isLoading={isUserUpdating}
            />
          </form>
        </Box>
      </Box>
      <Box className="mt-5">
        <h4 className="text-lGrey font-bold mb-5">Security</h4>
        <Box className="bg-lightBlack">
          <RecentLogins />
        </Box>

        <PinSection />

        <TwoFactorSection />

        <PasswordSection />

        {/* <WhiteListSection /> */}
      </Box>
    </>
  );
};

export default Settings;
