import { Box } from "components/shared/Box";
import { TransferExternalWallet } from "components/TransferExternalWallet";
import { WithdrawInstructionExternal } from "components/WithdrawInstructionExternal";
import { WithdrawHistory } from "components/WithdrawHistoryExternal";
import { Breadcrumbs } from "components/shared/Breadcrumbs";

const breadcrumbsWithdraw = [
  {
    title: "Affiliate",
    href: "/affiliate",
  },
  {
    title: `Withdraw`,
    href: `/affiliate/withdraw`,
  },
];

export const FinanceWithdrawView = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbsWithdraw} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <Box className="col-span-1 md:col-span-2 ">
          <Box className="bg-lightBlack">
            <TransferExternalWallet />
          </Box>
        </Box>
        <div className="col-span-1 md:col-span-1">
          <WithdrawInstructionExternal />
        </div>
        <Box className="col-span-1 md:col-span-3">
          <WithdrawHistory />
        </Box>
      </div>
    </>
  );
};
