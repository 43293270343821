import classNames from "classnames";
import { SettingsIcon } from "components/shared/Icons";
import { useUserContext } from "context/user.context";

import { useNavigate, useLocation } from "react-router-dom";
import { TbLogout } from "react-icons/tb";
import { useAuth } from "hooks";

export const Navbar = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSignout } = useAuth();

  const isSettingsPage = location.pathname === "/settings";

  const handleRedirectToSettings = () => navigate("/settings");

  return (
    <>
      <nav className="mt-5 md:mt-3 mb-5 md:mb-[30px] xl:h-[30px] flex items-center justify-between flex-col xl:flex-row">
        <div className="flex items-center justify-between w-full ">
          <ul className="[&>*]:ml-[33px] font-medium hidden sm:flex"></ul>

          <div className="block md:hidden">
            <div className="flex">
              <img
                className="h-[40px] w-[40px] rounded-xl border border-lightBlack object-cover"
                src={user?.avatar ?? "/images/defaultUserAvatar.jpeg"}
                alt={user?.firstName}
              />
              <div className="ml-5 max-w-[120px]">
                <h2 className="mb-[6px] text-sm text-white">
                  Hello, {user?.firstName}
                </h2>
                <p className="text-xs font-semibold text-grey text-ellipsis overflow-hidden">
                  {user?.email}
                </p>
              </div>
            </div>
          </div>

          <div className="flex">
            <div
              className={classNames(
                "ml-2 [&>svg]:fill-[#EAECEF] p-2 cursor-pointer",
                isSettingsPage && "bg-main rounded-xl  [&>svg]:fill-lightBlack"
              )}
              onClick={handleRedirectToSettings}
            >
              <SettingsIcon />
            </div>
            <div
              className={classNames("ml-2 p-2 cursor-pointer block xl:hidden")}
              onClick={handleSignout}
            >
              <TbLogout />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
