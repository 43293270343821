import classNames from "classnames";
// import useDemoConfig from "./useDemoConfig";
import React from "react";
import { AxisOptions, Chart } from "react-charts";

interface ChartAreaProps {
  onSelectRange: (date: string) => void;
  data: any[];
  chartContainerClassname?: string;
  primaryAxisProps?: AxisOptions<any>;
}

export default function Area({
  data: propsData,
  onSelectRange,
  chartContainerClassname = "",
  primaryAxisProps,
}: ChartAreaProps) {
  // const { data, randomizeData } = useDemoConfig({
  //   series: 2,
  //   dataType: "time",
  //   secondaryAxisStack: false,
  // });

  const primaryAxis = React.useMemo<
    AxisOptions<(typeof propsData)[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary as Date,
      showGrid: false,
      ...primaryAxisProps,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<(typeof propsData)[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        show: false,
        showGrid: false,
        stacked: true,
      },
    ],
    []
  );

  return (
    <>
      <div className={classNames('"h-full w-full"', chartContainerClassname)}>
        <Chart
          options={{
            data: propsData,
            primaryAxis,
            secondaryAxes,
            dark: true,
            getSeriesStyle: () => {
              return {
                color: `url(#0)`,
                opacity: 1,
              };
            },
            renderSVG: () => (
              <defs>
                <linearGradient
                  id="0"
                  x1="358"
                  y1="0"
                  x2="358"
                  y2="263.725"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#fff" stopOpacity="0.5" />
                  <stop offset="1" stopColor="#fff" stopOpacity="0.1" />
                </linearGradient>
              </defs>
            ),
          }}
        />
      </div>
      {/* <div className="mt-5 flex items-center justify-end [&>*]:ml-[10px]">
        <button
          type="button"
          // TODO: play with dayjs
          onClick={() => onSelectRange("1w")}
          className={classNames(
            "text-xs font-semibold transition-all hover:bg-lightBlack rounded-xl py-[7px] px-[10px] text-grey",
            true && "bg-lightBlack text-lGrey"
          )}
        >
          1W
        </button>
        <button
          type="button"
          onClick={() => onSelectRange("1m")}
          className={classNames(
            "text-xs font-semibold transition-all hover:bg-lightBlack rounded-xl py-[7px] px-[10px] text-grey"
          )}
        >
          1M
        </button>
        <button
          type="button"
          onClick={() => onSelectRange("3m")}
          className={classNames(
            "text-xs font-semibold transition-all hover:bg-lightBlack rounded-xl py-[7px] px-[10px] text-grey"
          )}
        >
          3M
        </button>
        <button
          type="button"
          onClick={() => onSelectRange("6m")}
          className={classNames(
            "text-xs font-semibold transition-all hover:bg-lightBlack rounded-xl py-[7px] px-[10px] text-grey"
          )}
        >
          6M
        </button>
        <button
          type="button"
          onClick={() => onSelectRange("1y")}
          className={classNames(
            "text-xs font-semibold transition-all hover:bg-lightBlack rounded-xl py-[7px] px-[10px] text-grey"
          )}
        >
          1Y
        </button>
      </div> */}
    </>
  );
}
