import {
  HomeIcon,
  FinanceIcon,
  ReferralIcon,
  SettingsIcon,
  MineIcon,
} from "components/shared/Icons";
import { ROUTES } from "config/routes";

export const mobileMenuItems = [
  {
    icon: <HomeIcon />,
    title: "Dashboard",
    link: ROUTES.home,
  },
  {
    icon: <FinanceIcon />,
    title: "Cources",
    link: ROUTES.courses,
  },
  {
    icon: <ReferralIcon />,
    title: "Affiliate program",
    link: ROUTES.affiliate,
  },
];
