import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";
import PhoneInput from "react-phone-number-input/react-hook-form";

import { signUp } from "requests";
import { SignUpValidationSchema, signUpValidation } from "const";
import { ResponseError } from "types";
import { errorParser } from "utils";

import { ROUTES } from "config/routes";

import { Button } from "components/shared/Button";
import { Input } from "components/shared/Input";
import { Checkbox } from "components/shared/Checkbox";

const SignUpStepTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = location.search.replace("?token=", "");

  const { error, isError, isLoading, mutate } = useMutation({
    mutationFn: signUp,
    onSuccess: () => {
      navigate(ROUTES.login);
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm<SignUpValidationSchema>({
    defaultValues: {
      login: "",
      firstName: "",
      lastName: "",
      phone: "",
      telegram: "",
      password: "",
      password_confirmation: "",
      agreeTerms: undefined,
    },
    resolver: zodResolver(signUpValidation),
    reValidateMode: "onChange",
  });

  const onSubmit = async (formValues: SignUpValidationSchema) => {
    mutate({ formValues, token });
  };

  return (
    <div className="px-2">
      <a
        href="https://pro-x.app/"
        rel="noreferrer"
        target="_blank"
        className="text-white text-3xl text-center block mx-auto mb-10"
      >
        <span className="text-main">PRO</span>X
      </a>
      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Journey Today
      </h3>

      <div className="max-w-sm [&>*]:mb-4 m-auto w-auto md:min-w-[385px]">
        <form className="[&>*]:w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="h-[38px] rounded-md px-4 mb-4"
            type="text"
            register={register}
            id="login"
            placeholder="Login"
            errorMessage={errors.login?.message}
          />
          <div className="pl-[30px] mb-4">
            <ol className="list-disc text-[10px]">
              <li className="text-placeholder">
                Should be more than 5 latin characters
              </li>
              <li className="text-placeholder">No numbers</li>
              <li className="text-placeholder">No special symbols</li>
            </ol>
          </div>
          <Input
            className="h-[38px] rounded-md px-4 mb-4"
            type="text"
            register={register}
            id="firstName"
            placeholder="First name"
            errorMessage={errors.firstName?.message}
          />
          <Input
            className="h-[38px] rounded-md px-4 mb-4"
            type="text"
            register={register}
            id="lastName"
            placeholder="Last name"
            errorMessage={errors.lastName?.message}
          />
          <PhoneInput
            name="phone"
            control={control}
            placeholder="+123456789"
            className="mb-4 md:w-sm rounded-[12px] border border-[#333333] py-3 pl-4  bg-mainBg outline-none focus:border-secondaryButton transition-all text-white placeholder:text-placeholder placeholder:text-sm [&>input]:bg-none"
          />
          {errors.phone?.message && (
            <div className="text-red text-[12px] mb-4">
              {errors.phone?.message}
            </div>
          )}
          <div className="relative w-full">
            <div className="text-white absolute left-[10px] top-[8px]">@</div>
            <Input
              className="h-[38px] rounded-md px-4 mb-4 !pl-[30px] !w-full"
              type="text"
              register={register}
              id="telegram"
              placeholder="Telegram"
              errorMessage={errors.telegram?.message}
            />
          </div>

          <Input
            className="h-[38px] rounded-md px-4 mb-4 !w-full"
            type="password"
            register={register}
            id="password"
            placeholder="Password"
            errorMessage={errors.password?.message}
          />
          <div className="pl-[30px] mb-4">
            <ol className="list-disc text-[10px]">
              <li className="text-placeholder">
                Upper case letters: from A to Z
              </li>
              <li className="text-placeholder">
                Lower case letters: from a to z
              </li>
              <li className="text-placeholder">Numbers: from 0 to 9</li>
              <li className="text-placeholder">
                Special symbols: {`$ & + , : ; = ? @ # | ' < > . ^ * ( ) % ! -`}
              </li>
            </ol>
          </div>

          <Input
            className="h-[38px] rounded-md px-4 !w-full"
            type="password"
            register={register}
            id="password_confirmation"
            placeholder="Confirm your password"
            errorMessage={errors.password_confirmation?.message}
          />

          <div className="mt-[35px] mb-4">
            <div className="flex justify-center items-center">
              <p className="text-lightGrey mr-[5px] text-white">
                Agree to the{" "}
                <Link className="text-main" to="/terms.pdf" target="_blank">
                  Terms of use
                </Link>{" "}
                of the platform{" "}
              </p>
              <Checkbox
                watch={watch}
                id="agreeTerms"
                register={register}
                checked={getValues("agreeTerms")}
                errorMessage={errors.agreeTerms?.message}
              />
            </div>

            {errors.agreeTerms?.message && (
              <div className="text-red text-[12px] text-center">
                {errors.agreeTerms?.message}
              </div>
            )}
          </div>

          {/* {showCaptcha && (
            <PuzzleCaptcha
              onVerifyCallback={() => {
                setCaptchaPassed(true);
                mutate({ formValues: getValues(), token });
              }}
            />
          )} */}

          {isError && (error as ResponseError)?.response?.data.error && (
            <div className="text-center text-red mt-5">
              {errorParser(error as ResponseError)}
            </div>
          )}
          <Button
            isLoading={isLoading}
            type="submit"
            text="Sign up"
            className="bg-main font-bold  w-full text-sm mt-5"
          />
        </form>
      </div>
    </div>
  );
};

export default SignUpStepTwo;
