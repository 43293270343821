import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { getWithdrawHistory } from "requests";
import { TRANSACTIONS_PER_PAGE } from "const";
import { FinancialTransactionStatus, FinancialTransactionType } from "types";

import { SearchInput } from "components/shared/SearchInput";
import { useForm } from "react-hook-form";
import { ViewContent } from "components/shared/ViewContent";
import { ChevronDown } from "components/shared/Icons";
import classNames from "classnames";
import { useToggle } from "hooks";
import DatePickerRange from "components/DatePickerRange";

const TableRow = () => {
  const [showInfo, toggleInfo] = useToggle();

  return (
    <div className="py-4 border-t border-lightBlack">
      <div
        className="grid grid-cols-2 md:grid-cols-4 cursor-pointer gap-3 md:gap-5"
        onClick={toggleInfo}
      >
        <div className="flex flex-col md:items-center md:flex-row">
          <h3 className="text-main font-semibold text-sm">488,54 USDT</h3>
          <p className="font-semibold text-xs text-lGrey md:ml-[10px]">
            <span className="text-grey">Fee</span> 0.001
          </p>
        </div>
        <div className="flex items-center">
          <h3 className="text-lGrey font-semibold text-sm ">ERC20</h3>
        </div>
        <div>
          <h3 className="text-lGrey font-semibold text-sm">
            <span className="mr-[6px]">15:01</span> <span>12-21-2023</span>
          </h3>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-success font-semibold text-sm">Completed</p>
            <div
              className={classNames(
                "transition-all",
                showInfo ? "rotate-180" : "rotate-0"
              )}
            >
              <ChevronDown />
            </div>
          </div>
        </div>
      </div>
      {showInfo && (
        <div className="flex flex-col mt-4">
          <div className="mb-4">
            <h2 className="font-semibold text-xs text-grey">Address</h2>
            <p className="font-semibold text-sm text-lGrey break-all">
              0x060f7312d3e5bcab34626ce5a7a18704196a6f3e0b7094b0baca48
            </p>
          </div>
          <div>
            <h2 className="font-semibold text-xs text-grey">TxID</h2>
            <p className="font-semibold text-sm text-lGrey break-all">
              0x060f7312d2d3e5bcab34626ca7a18704196a6f3e0b7094b0baca48
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

// TODO: refactor
const statuses: Record<FinancialTransactionStatus, string> = {
  [FinancialTransactionStatus.CANCELLED]: "Cancelled",
  [FinancialTransactionStatus.COMPLETED]: "Completed",
  [FinancialTransactionStatus.DECLINED]: "Declined",
  [FinancialTransactionStatus.FAILED]: "Failed",
  [FinancialTransactionStatus.PENDING]: "Pending",
};

export const WithdrawHistory = () => {
  const [searchParams] = useSearchParams();

  const { setValue, getValues } = useForm({
    defaultValues: {
      searchQuery: "",
    },
  });

  const handleSearchInputChange = (value: string | number) => {
    setValue("searchQuery", value as string);
    // refetch();
  };

  const currentPage = searchParams.get("page")
    ? +(searchParams.get("page") as string) <= 0
      ? 1
      : searchParams.get("page")
    : 1;

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "withdrawTrans",
      +(currentPage as number),
      999999,
      FinancialTransactionType.WITHDRAW,
    ],
    queryFn: getWithdrawHistory,
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-5 flex-col md:flex-row">
        <h3 className="text-lGrey font-semibold mb-5 md:mb-0">
          Withdrawal history{" "}
        </h3>
        {/* <div className="flex items-center flex-col md:flex-row">
          <SearchInput
            value={getValues("searchQuery")}
            placeholder="Enter your request"
            onChange={handleSearchInputChange}
            containerClassName="md:w-[450px] w-full"
          />
          <div className="md:ml-[10px]">
            <DatePickerRange />
          </div>
        </div> */}
      </div>
      <div>
        <div className="hidden md:grid grid-cols-4 pb-5">
          <div className="text-grey font-semibold text-xs">Amount</div>
          <div className="text-grey font-semibold text-xs">Network</div>
          <div className="text-grey font-semibold text-xs">Date</div>
          <div className="text-grey font-semibold text-xs">Status</div>
        </div>
        <div className="max-h-[536px] overflow-y-auto pr-5">
          <ViewContent isError={isError} isLoading={isLoading} data={data}>
            {(data: any) =>
              data.map((referralAccrual: any) => {
                return (
                  <>
                    <TableRow key={referralAccrual.id} />
                  </>
                );
              })
            }
          </ViewContent>
        </div>
      </div>
    </div>
  );
};
