import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";

import { signUpStepOne } from "requests";
import { SignUpEmailValidationSchema, signUpWithEmail } from "const";

import { Input } from "components/shared/Input";
import { Button } from "components/shared/Button";
import { ResponseError } from "types";
import { errorParser } from "utils";

const SignUpStepOne = () => {
  const location = useLocation();
  const isExpired = location.search.includes("error=expired");
  const isTwoAccs = location.search.includes("error=ip");

  const referralCode = location.search.includes("?referral-code")
    ? location.search.replace("?referral-code=", "")
    : null;

  const { error, isError, isLoading, mutate, data } = useMutation({
    mutationFn: signUpStepOne,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<SignUpEmailValidationSchema>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(signUpWithEmail),
  });

  const onSubmit = async (formValues: SignUpEmailValidationSchema) => {
    const value = referralCode
      ? { ...formValues, ref: referralCode }
      : formValues;
    mutate(value);
  };

  return (
    <div className="px-2">
      <a
        href="https://pro-x.app/"
        rel="noreferrer"
        target="_blank"
        className="text-white text-3xl text-center block mx-auto mb-10"
      >
        <span className="text-main">PRO</span>X
      </a>
      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Journey Today
      </h3>
      <p className="text-[#B8B8B8] mb-6 w-[305px] text-center m-auto">
        Enter your email and receive verification link with further instructions
      </p>
      <form
        className="m-auto max-w-[360px] mb-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          placeholder="Email"
          id="email"
          register={register}
          className="h-[38px] mb-4 rounded-md px-4 w-full"
          errorMessage={errors.email?.message}
        />
        {isExpired && !isSubmitted && (
          <div className="text-center text-red mt-5">
            Token has expired, please resend again
          </div>
        )}
        {isTwoAccs && !isSubmitted && (
          <div className="text-center text-red mt-5">
            Cannot create more than two accounts from the same ip
          </div>
        )}
        {isError && (error as ResponseError)?.response?.data.error && (
          <div className="text-center text-red mt-5">
            {errorParser(error as ResponseError)}
          </div>
        )}
        {data && (
          <div className="text-center text-success mt-5">{data.message}</div>
        )}
        <Button
          type="submit"
          text={isExpired ? "Resend" : "Send email"}
          className="bg-main w-[360px]  py-0 mt-[57px] font-bold"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default SignUpStepOne;
