import { Box } from "components/shared/Box";
import { useNavigate } from "react-router";
import { Button } from "components/shared/Button";
import { CoursesPageTabs } from "types";

import { CoursesPurchased } from "components/CoursesPurchased";
import { CoursesNotPurchased } from "components/CoursesNotPurchased";
import classNames from "classnames";
import { useState } from "react";

export const CoursesView = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(CoursesPageTabs.PURCHASED);

  const handleButtonNavigation = (url: string) => navigate(url);

  const handleSelectChartRange = (value: any) => {
    console.log(value, "value");
  };

  const renderLayout = () => {
    if (currentTab === CoursesPageTabs.PURCHASED)
      return (
        <Box className="col-span-1 md:col-span-4">
          <h5 className="mb-2 font-bold text-lGrey">Purchased courses</h5>
          <CoursesPurchased />
        </Box>
      );

    if (currentTab === CoursesPageTabs.AVAILABLE)
      return (
        <Box className="col-span-1 md:col-span-4">
          <h5 className="mb-2 font-bold text-lGrey">
            Available to buy courses
          </h5>
          <CoursesNotPurchased />
        </Box>
      );
  };

  return (
    <div className="grid grid-cols-1  md:grid-cols-4">
      <div className="flex md:items-center md:flex-row mb-5 col-span-2">
        <div className="flex items-center flex-col md:flex-row  w-[280px] md:w-full m-auto">
          <Button
            type="button"
            className={classNames(
              "md:w-[204px] rounded-xl font-bold text-grey   py-[6px]",
              currentTab === CoursesPageTabs.PURCHASED &&
                "bg-dMain border border-main text-white"
            )}
            onClick={() => setCurrentTab(CoursesPageTabs.PURCHASED)}
          >
            Purchased
          </Button>
          <Button
            type="button"
            className={classNames(
              "md:w-[204px] rounded-xl font-bold text-grey mt-4 md:mt-0 py-[6px] md:ml-4",
              currentTab === CoursesPageTabs.AVAILABLE &&
                "bg-dMain border border-main text-white"
            )}
            onClick={() => setCurrentTab(CoursesPageTabs.AVAILABLE)}
          >
            Available for purchase
          </Button>
        </div>
      </div>

      {renderLayout()}
    </div>
  );
};
