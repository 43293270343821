import { RefferalTransfer } from "components/ReferralForm";
import { ReferralAccrualCounter } from "components/ReferralAccrualCounter";
import { useQuery } from "react-query";
import { getBalances } from "requests";
import { formatNumber } from "utils";

export const ReferralBonuses = () => {
  const { data: balance } = useQuery({
    queryKey: "balances",
    queryFn: getBalances,
    select: (balance) => {
      const mainBalance = balance.find(({ type }: any) => type === "balance");
      const dividendBalance = balance.find(
        ({ type }: any) => type === "dividend"
      );
      const referralBalance = balance.find(
        ({ type }: any) => type === "referral"
      );

      return {
        balance: mainBalance,
        dividend: dividendBalance,
        referral: referralBalance,
      };
    },
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-5">
        <h3 className="text-lGrey font-bold mb-[6px]">
          Overall affiliate balance
        </h3>
        <h2 className="font-bold text-lGrey text-2xl flex items-center">
          {formatNumber("USD", balance?.referral.balance)}
          <span className="text-xs text-lGrey font-semibold ml-[10px]">
            USD
          </span>
        </h2>
      </div>
      {/* <div>
        <h3 className="text-lGrey text-sm font-bold mb-[6px]">
          Referral accrual counter
        </h3>
        <div className="flex items-center mb-5">
          <ReferralAccrualCounter earned={169.9231387} />
          <div className="ml-[6px] font-semibold text-lGrey text-xs">GQC</div>
        </div>
        <RefferalTransfer />
      </div> */}
    </div>
  );
};
