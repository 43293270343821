import { FC } from "react";

interface UserInfoBlockProps {
  username: string;
  email: string;
  showIcon?: boolean;
}

export const UserInfoBlock: FC<UserInfoBlockProps> = ({
  username,
  email,
  showIcon = true,
}) => {
  return (
    <div className="flex items-center">
      {showIcon && (
        <div className="mr-[6px] hidden md:block">
          <img className="w-9 h-9" src={`/images/user${3}.png`} alt="user1" />
        </div>
      )}
      <div className="flex flex-col">
        <h3 className="text-lGrey font-semibold max-w-[120px] md:max-w-[180px] text-ellipsis overflow-hidden">
          {username}
        </h3>
        <p className="text-grey font-semibold text-xs max-w-[120px] md:max-w-[140px] text-ellipsis overflow-hidden">
          {email}
        </p>
      </div>
    </div>
  );
};
