import { FC, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";

import { Input } from "../Input";
import { Button } from "../Button";

import { ResponseError } from "types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  securedComponentFormSchema,
  SecuredComponentValidationSchema,
} from "const";
import { errorParser } from "utils";
import { useUserContext } from "context/user.context";
import { Link } from "react-router-dom";

interface SecuredWithPinProps {
  className?: string;
  sendRequest: any;
  error: any;
  isLoading: boolean;
}

export const SecuredComponentWith: FC<
  PropsWithChildren<SecuredWithPinProps>
> = ({ sendRequest, error, isLoading }) => {
  const { user } = useUserContext();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SecuredComponentValidationSchema>({
    defaultValues: {
      pin: "",
      totp: "",
      pinEnabled: user?.pin,
      "2faEnabled": user?.["2fa"],
    },
    resolver: zodResolver(securedComponentFormSchema),
  });

  const onSubmit = (formValues: any) => {
    sendRequest(formValues);
  };

  return (
    <div>
      <h3 className="text-lGrey text-sm font-bold  mb-5 pr-[30px]">
        In order to proceed, please, enter your security pins
      </h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        {user?.pin ? (
          <Input
            register={register}
            type="password"
            id="pin"
            placeholder="Your pin"
            errorMessage={errors.pin?.message}
            className="w-full mb-5"
          />
        ) : (
          <Link to="/settings" className="text-main font-semibold text-sm">
            Press this link in order to set pin
          </Link>
        )}

        {user?.["2fa"] && (
          <Input
            register={register}
            type="number"
            id="totp"
            placeholder="2FA code"
            errorMessage={errors.totp?.message}
            className="!w-full mb-5"
          />
        )}
        {error && (error as ResponseError)?.response?.data.error ? (
          <div className="text-center text-red mb-5">
            {errorParser(error as ResponseError)}
          </div>
        ) : null}
        {!!user?.pin && (
          <Button
            type="submit"
            text="Submit"
            className=" bg-main font-semibold"
            isLoading={isLoading}
          />
        )}
      </form>
    </div>
  );
};
