import classNames from "classnames";
import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";
import { useConfettiContext } from "context/confetti.context";
import { useAuth } from "hooks";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cancelTransaction } from "requests";
import { cutText } from "utils";

interface CourseInfoBlockProps {
  courseInfo: any;
  queryKey: string;
  refetch?: any;
}

export const CourseBlock = ({
  courseInfo,
  queryKey,
  refetch,
}: CourseInfoBlockProps) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const { setShowConfetti } = useConfettiContext();

  const { mutate: mutateCancelTransaction, isLoading: isCancelling } =
    useMutation({
      mutationFn: cancelTransaction,
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKey, { exact: true });
        toast.success("Course purchase was cancelled");
        // setShowConfetti(true);
      },
      onError: () => {
        toast.error("Error while purchasing course");
      },
    });

  const handleButtonNavigation = (url: string) => navigate(url);

  const courseData = courseInfo.translation;

  const handleClickCourseButton = () => {
    courseInfo.isPurchased
      ? handleButtonNavigation(`/courses/${courseInfo.id}`)
      : // : mutateBuyCourse({ courseId: courseInfo.id });
        // https://api.pro-x.app/api
        window.location.replace(
          `https://api.pro-x.app/buy-course/${courseInfo?.id}?token=${token}`
        );
  };

  const cancelPayment = () => {
    mutateCancelTransaction(courseInfo.pendingTransactionId);
  };

  const checkTransStatus = () => {
    refetch();
  };

  const renderButtonLogic = () => {
    if (courseInfo.hasPendingTransaction) {
      return (
        <>
          <Button
            type="button"
            className="bg-main mt-4"
            onClick={() => checkTransStatus()}
          >
            Refresh status
          </Button>
          <Button
            type="button"
            className="mt-4 hover:bg-main"
            onClick={() => cancelPayment()}
            disabled={isCancelling}
          >
            Cancel payment
          </Button>
        </>
      );
    }

    if (!courseInfo.isPurchased)
      return (
        <Button
          type="button"
          className="bg-main mt-4"
          onClick={() => {
            handleClickCourseButton();
          }}
        >
          Buy course for ${courseInfo.price}
        </Button>
      );

    return (
      <Button
        type="button"
        className="bg-main mt-4"
        onClick={() => handleClickCourseButton()}
      >
        View course
      </Button>
    );
  };

  return (
    <Box className="bg-lightBlack transition-all hover:scale-[.98] p-0 overflow-hidden pb-3">
      <div
        className={classNames(
          "text-white flex flex-col justify-between items-center md:h-[300px]"
        )}
      >
        {!courseInfo.hasPendingTransaction && (
          <Link to={`/courses/${courseInfo.id}`} className="text-main">
            <img
              className="h-[150px] w-full object-contain"
              src={`https://api.pro-x.app/images/${courseData.image}`}
              alt=""
            />
          </Link>
        )}
        <div className="pt-0 m-auto">
          <h2 className="my-4 text-center">
            <Link
              to={`/courses/${courseInfo.id}`}
              className="text-main hover:text-white transition-all"
            >
              {cutText(courseData.title, 35)}
            </Link>
          </h2>

          {renderButtonLogic()}
        </div>
      </div>
    </Box>
  );
};
