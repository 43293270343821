import { AxiosResponse } from "axios";
import { useAuth } from "hooks";
import { createContext, FC, useMemo, useContext } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { toast } from "react-toastify";

import { getUser, updateUserInfo, updateUserAvatar } from "requests";
import { UpdateUserProps, UserProps } from "types";
import { errorParser } from "utils";

interface UserContextProps {
  user: UserProps | null;
  isProfileLoading: boolean;
  profileError: any;
  updateUser: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<UpdateUserProps>,
    unknown
  >;
  isUserUpdating: boolean;
  updatingError: any;
  isUserAvatarUpdating: boolean;
  updatingAvatarError: any;
  mutateUserAvatar: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<any>,
    unknown
  >;
}

const initialCtx = {
  user: null,
  isProfileLoading: false,
  profileError: null,
  updateUser: () => {},
  isUserUpdating: false,
  updatingError: null,
  isUserAvatarUpdating: false,
  updatingAvatarError: null,
  mutateUserAvatar: () => {},
};

export const UserContext = createContext<UserContextProps>(initialCtx);

export const UserContextProvider: FC<any> = ({ children }) => {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  const {
    data: user,
    isLoading: isProfileLoading,
    error: profileError,
    refetch,
  } = useQuery({
    queryKey: "user",
    queryFn: getUser,
    enabled: !!token,
  });

  const {
    isLoading: isUserUpdating,
    error: updatingError,
    mutate: updateUser,
  } = useMutation({
    mutationFn: updateUserInfo,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries("user", { exact: true });

      toast(data.message);
    },
    onError: (error) => {
      // @ts-expect-error
      toast(errorParser(error) ?? "Error while making request");
    },
  });

  const {
    isLoading: isUserAvatarUpdating,
    error: updatingAvatarError,
    mutate: mutateUserAvatar,
  } = useMutation({
    mutationFn: updateUserAvatar,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries("user", { exact: true });
      toast(data.message);
      refetch();
    },
    onError: () => {
      toast("Error while updating user, please, try later");
    },
  });

  const value = useMemo(
    () => ({
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      isUserAvatarUpdating,
      updatingAvatarError,
      mutateUserAvatar,
    }),
    [
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      isUserAvatarUpdating,
      updatingAvatarError,
      mutateUserAvatar,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
