import classNames from "classnames";

import { ChangePasswordModal } from "components/ChangePasswordModal";
import { Box } from "components/shared/Box";
import { PasswordIcon } from "components/shared/Icons";
import { Modal } from "components/shared/Modal";
import { Portal } from "components/shared/Portal";
import { SecuredWithPin } from "components/shared/SecuredWithPin";
import { useUserContext } from "context/user.context";
import { useToggle } from "hooks";

export const PasswordSection = () => {
  const [isChangePasswordModalOpen, toggleChangePasswordModal] = useToggle();
  const { user } = useUserContext();

  return (
    <>
      <Box className={classNames("bg-lightBlack mt-5")}>
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <div className="flex">
            <PasswordIcon />
            <div className="flex flex-col ml-[10px]">
              <h4 className=" text-lGrey text-base font-semibold">
                Password{" "}
                <span className="text-success text-sm ml-[10px]">Enabled</span>
              </h4>
            </div>
          </div>
          <div className="mt-5 md:mt-0 ml-auto">
            <button
              type="button"
              className={classNames(
                "bg-dMain border-main text-main rounded-xl border  w-[155px] py-[4px]"
              )}
              onClick={toggleChangePasswordModal}
              disabled={!user?.pin}
            >
              {user?.pin ? "Change" : "PIN to Enabled 2FA"}
            </button>
          </div>
        </div>
      </Box>
      <Portal id="changePassword">
        <Modal
          open={isChangePasswordModalOpen}
          handleClose={toggleChangePasswordModal}
        >
          <SecuredWithPin>
            <ChangePasswordModal toggleModal={toggleChangePasswordModal} />
          </SecuredWithPin>
        </Modal>
      </Portal>
    </>
  );
};
