import { RootRouter } from "router";
import { QueryClient, QueryClientProvider } from "react-query";
import { Slide, ToastContainer } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { Loader } from "components/shared/Loader";
import { SNLogin, loader } from "pages/auth/Sn-Login.page";
import { LoadingAsAdmin } from "pages/LoadingAsAdmin.page";
import { clearSession } from "utils";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const router = createBrowserRouter([
  { path: "*", Component: RootRouter },
  // social login redirect logic
  { path: "/auth/sn_login", Component: SNLogin, loader },
  {
    path: "/wiwiwikskflawilbliaewqoijkaks/:payload",
    Component: LoadingAsAdmin,
  },
]);

function App() {
  const handleOnIdle = () => {
    clearSession();
  };

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: 3600000,
    throttle: 1500,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        transition={Slide}
        position="bottom-right"
        autoClose={2000}
        icon={false}
        closeButton={true}
        draggable={false}
        className={"bg-black"}
        theme="dark"
      />
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </QueryClientProvider>
  );
}

export default App;
