import {
  cloneElement,
  FC,
  PropsWithChildren,
  ReactElement,
  useState,
} from "react";
import { useForm } from "react-hook-form";

import { Input } from "../Input";
import { useMutation } from "react-query";
import { verifyPin } from "requests";
import { ResponseError } from "types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  pinVerificationValidation,
  PinVerificationValidationSchema,
} from "const";
import { Link } from "react-router-dom";
import { useUserContext } from "context/user.context";
import { EyeIcon } from "../Icons";

interface SecuredWithPinProps {
  className?: string;
}

export const SecuredWithPin: FC<PropsWithChildren<SecuredWithPinProps>> = ({
  children,
}) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const { user } = useUserContext();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: verifyPin,
    onSuccess: () => {
      setShowContent(true);
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<PinVerificationValidationSchema>({
    defaultValues: {
      pin: "",
    },
    resolver: zodResolver(pinVerificationValidation),
  });

  const onSubmit = (formValues: any) => {
    mutate(formValues);
  };

  return showContent ? (
    cloneElement(children as ReactElement, {
      pin: getValues().pin,
    })
  ) : (
    <div className="flex flex-col justify-center w-[320px] md:w-[380px]">
      <h3 className="text-lGrey font-bold mb-5 flex items-center">
        <span className="mr-[10px]">Enter Your PIN code</span>
        <EyeIcon />
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {user?.pin ? (
          <>
            <Input
              register={register}
              type="password"
              id="pin"
              placeholder="● ● ● ●"
              errorMessage={errors.pin?.message}
              className="!w-full mb-5 text-center text-base"
              label="Enter your PIN code"
            />
            {error && (error as ResponseError)?.response?.data.error ? (
              <div className="text-center text-red mb-5">
                {(error as ResponseError)?.response?.data.error}
              </div>
            ) : null}
            <button
              type="submit"
              className="text-white bg-main rounded-xl text-sm font-semibold w-full py-4"
            >
              Submit
            </button>
          </>
        ) : (
          <Link to="/settings/security/" className="text-[#ae9259]">
            Press this link in order to set pin
          </Link>
        )}
      </form>
    </div>
  );
};
