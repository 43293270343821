import { useNavigate } from "react-router-dom";

import { Button } from "components/shared/Button";
import { SocialAuth } from "components/SocialAuth";
import { ROUTES } from "config/routes";

const SignUp = () => {
  const navigate = useNavigate();

  const handleRedirectToLogin = () => {
    navigate("/auth/login");
  };

  const handleRedirectToSignUpForm = () => {
    navigate(ROUTES.signUpStepOne);
  };

  return (
    <div className="w-[350px] md:w-auto">
      <a
        href="https://pro-x.app/"
        rel="noreferrer"
        target="_blank"
        className="text-white text-3xl text-center block mx-auto mb-10"
      >
        <span className="text-main">PRO</span>X
      </a>
      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Journey Today
      </h3>
      <div className="max-w-sm [&>*]:mb-4 m-auto mb-[80px]">
        <SocialAuth />
        <Button
          onClick={handleRedirectToSignUpForm}
          type="button"
          text="Sign up with email"
          className="bg-main w-full text-sm font-bold"
        />
      </div>
      <div className="flex justify-center flex-col">
        <div className="text-center text-white">Already have an account?</div>
        <Button
          onClick={handleRedirectToLogin}
          type="button"
          className="bg-main font-bold text-black mt-4 text-sm hover:shadow-buttonShadow"
          text="Sign in"
        />
      </div>
    </div>
  );
};

export default SignUp;
