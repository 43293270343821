import { useQuery } from "react-query";
import { getCourses } from "requests";

import { Loader } from "components/shared/Loader";

import { CourseDto } from "types";
import { Box } from "components/shared/Box";

import { ViewContent } from "components/shared/ViewContent";
import { CourseBlock } from "components/CourseBlock";

export const OverallBalance = () => {
  const {
    data: courses,
    isLoading: isCourseLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery<CourseDto[]>({
    queryKey: "courses",
    queryFn: getCourses,
  });

  if (isCourseLoading) return <Loader className="!bg-white" />;

  return (
    <>
      <div className="flex flex-col justify-between md:flex-row">
        <div>
          <h5 className="mb-2 font-bold text-lGrey">Available Courses</h5>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 md:grid-col-4 lg:grid-cols-3 xl-l:grid-cols-4 gap-[10px] max-h-[345px] overflow-y-auto">
        <ViewContent
          isLoading={isCourseLoading || isFetching}
          data={courses}
          isError={isError}
        >
          {(courses: CourseDto[]) => {
            if (courses.length <= 1) {
              return (
                <>
                  {courses.map((course: CourseDto) => {
                    return (
                      <CourseBlock
                        courseInfo={course}
                        key={course.id}
                        queryKey="courses"
                        refetch={refetch}
                      />
                    );
                  })}
                  <Box className="bg-lightBlack transition-all  border-main">
                    <div className="flex flex-col md:h-[300px] items-center justify-center font-bold text-placeholder">
                      More is yet to come...
                    </div>
                  </Box>
                </>
              );
            }

            return courses.map((course: CourseDto) => {
              return (
                <CourseBlock
                  key={course.id}
                  courseInfo={course}
                  queryKey="courses"
                  refetch={refetch}
                />
              );
            });
          }}
        </ViewContent>
      </div>
    </>
  );
};
