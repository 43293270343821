import { createContext, FC, useMemo, useContext, useState } from "react";

interface WidgetContextProps {
  showConfetti: boolean;
  setShowConfetti: (value: boolean) => void;
}

const initialCtx = {
  showConfetti: false,
  setShowConfetti: () => {},
};

export const ConfettiContext = createContext<WidgetContextProps>(initialCtx);

export const ConfettiContextProvider: FC<any> = ({ children }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  const value = useMemo(
    () => ({
      showConfetti,
      setShowConfetti,
    }),
    [showConfetti, setShowConfetti]
  );

  return (
    <ConfettiContext.Provider value={value}>
      {children}
    </ConfettiContext.Provider>
  );
};

export const useConfettiContext = () => useContext(ConfettiContext);
