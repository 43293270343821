import classNames from "classnames";
import { UseFormRegister, WatchInternal } from "react-hook-form";

interface InputProps {
  label?: string | JSX.Element;
  id: string;
  LabelIcon?: JSX.Element;
  LabelHint?: JSX.Element;
  InputIcon?: JSX.Element;
  register: UseFormRegister<any>;
  errorMessage?: string;
  className?: string;
  disabled?: boolean;
  watch?: WatchInternal<string>;
  checked: boolean;
}

export const Checkbox = ({
  errorMessage,
  id,
  disabled,
  register,
  watch,
  className,
  checked,
}: InputProps) => {
  watch && watch(id);

  return (
    <>
      <div>
        <label
          htmlFor={id}
          className="block relative h-[18px] w-[18px] rounded-[3px] border border-white cursor-pointer"
        >
          {checked && (
            <img
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              src="/icons/check.svg"
              alt="check icon"
            />
          )}
        </label>
        <input
          hidden
          className={classNames(
            "w-sm rounded-[12px] border border-[#333333] py-3 px-10 bg-mainBg outline-none focus:border-secondaryButton transition-colors text-white placeholder:text-placeholder",
            className && className
          )}
          type="checkbox"
          id={id}
          autoComplete="false"
          disabled={disabled}
          {...register(id)}
        />
      </div>
    </>
  );
};
