import {
  HomeIcon,
  FinanceIcon,
  ReferralIcon,
  SettingsIcon,
} from "components/shared/Icons";
import { ROUTES } from "config/routes";

export const menuItems = [
  {
    icon: <HomeIcon />,
    title: "Dashboard",
    link: ROUTES.home,
  },
  {
    icon: <FinanceIcon />,
    title: "Courses",
    link: ROUTES.courses,
  },
  {
    icon: <ReferralIcon />,
    title: "Affiliate program",
    link: ROUTES.affiliate,
  },
  {
    icon: <SettingsIcon />,
    title: "Settings",
    link: ROUTES.settings,
  },
  // {
  //   icon: <MineIcon />,
  //   title: "Mine",
  //   link: ROUTES.mine,
  // },
];
