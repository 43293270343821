import { TelegramIcon } from "components/shared/Icons";
import { UserInfoBlock } from "components/UserInfoBlock";
import { useUserContext } from "context/user.context";
import React from "react";

export const ReferralSeniorPartner = () => {
  const { user } = useUserContext();

  return (
    <>
      <h3 className="text-lGrey font-semibold mb-5">Your senior partner</h3>
      <div className="flex items-center justify-between">
        <UserInfoBlock
          username={user?.referred_by ?? "-"}
          email={user?.referred_by_email ?? "-"}
        />
        {user && user?.referred_by_telegram && (
          <div
            className="rounded-xl bg-lightBlack flex items-center py-[11px] px-7 cursor-pointer"
            onClick={() =>
              window.open(
                `https://t.me/${user?.referred_by_telegram}`,
                "_blank"
              )
            }
          >
            <h3 className="text-lGrey font-semibold  mr-[10px] text-sm ">
              Telegram
            </h3>
            <TelegramIcon />
          </div>
        )}
      </div>
    </>
  );
};
