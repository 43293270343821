import { useQuery } from "react-query";
import { ViewContent } from "components/shared/ViewContent";
import { getCoursesPurchased } from "requests";
import { CourseBlock } from "components/CourseBlock";

export const CoursesPurchased = () => {
  const {
    data: courses,
    isLoading: isCoursesLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: "coursesPurchased",
    queryFn: getCoursesPurchased,
  });

  return (
    <div className="grid grid-cols-1 md:grid-col-4 lg:grid-cols-5 gap-[10px]  overflow-y-auto">
      <ViewContent
        data={courses}
        isLoading={isCoursesLoading || isFetching}
        isError={isError}
      >
        {(data: any) => {
          return data.map((courseInfo: any) => {
            return (
              <CourseBlock
                key={courseInfo.id}
                courseInfo={courseInfo}
                queryKey="coursesPurchased"
                refetch={refetch}
              />
            );
          });
        }}
      </ViewContent>
    </div>
  );
};
