import { useNavigate } from "react-router";

import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";
import { useUserContext } from "context/user.context";

export const ReferralInfoBlock = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const handleButtonNavigation = (url: string) => navigate(url);

  return (
    <>
      <Box className=" bg-lightBlack border-none flex items-center justify-between">
        <h3 className=" text-lGrey font-semibold">Total partners</h3>
        <h3 className=" text-lGrey font-semibold">{user?.referralsCount}</h3>
      </Box>

      <Box className="mt-[10px] bg-lightBlack border-none flex items-center justify-between">
        <h3 className=" text-lGrey font-semibold">
          Active partners percentage
        </h3>
        <h3 className=" text-lGrey font-semibold">
          {user?.activeReferralsPercentage}%
        </h3>
      </Box>
      <Button
        text="Withdrawal"
        type="button"
        className="rounded-xl bg-main !p-[6px] text-sm font-semibold text-black md:!p-2 mt-4 h-[50px]"
        onClick={() => handleButtonNavigation(`/affiliate/withdraw`)}
      />
    </>
  );
};
