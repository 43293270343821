import { useRef, useState } from "react";
import {
  TbRewindForward10,
  TbRewindBackward10,
  TbPlayerPlayFilled,
  TbPlayerPauseFilled,
} from "react-icons/tb";
import classNames from "classnames";
import ReactPlayer from "react-player/lazy";

export const CustomVideoPlayer = ({ src }: any) => {
  const videoRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const handleSeek = (time: any) => {
    videoRef.current.currentTime = time;
  };

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        /* Firefox */
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE/Edge */
        videoRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-expect-error
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        // @ts-expect-error
        document.mozCancelFullScreen();
        // @ts-expect-error
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        // @ts-expect-error
        document.webkitExitFullscreen();
        // @ts-expect-error
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        // @ts-expect-error
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  function calculatePercentageDifference(
    maxValue: number,
    currentValue: number
  ) {
    if (videoRef.current) {
      return (currentValue / maxValue) * 100;
    } else {
      return 0;
    }
  }

  return (
    <div className="relative overflow-hidden">
      <video
        ref={videoRef}
        src={src}
        controls={false}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        // style={{ width: "100%", height: 400 }}
        style={{ width: "100%" }}
        contextMenu="false"
        className="pointer-events-none"
        preload="auto"
      />

      <div className="bg-lightBlack absolute w-full bottom-0  rounded-lg p-2 flex items-center justify-between">
        <div className="absolute w-full h-[5px] bg-lMain -top-[5px] left-1/2 -translate-x-1/2">
          <div
            className={classNames("absolute bg-main h-[5px]")}
            style={{
              width: `${calculatePercentageDifference(duration, currentTime)}%`,
            }}
          />
        </div>
        <div className="w-[20px]"></div>
        <div className="flex">
          <button onClick={() => handleSeek(currentTime - 10)}>
            <TbRewindBackward10 size={20} color="#fff" />
          </button>
          <button onClick={handlePlayPause} className="mx-5 ">
            {!isPlaying ? (
              <TbPlayerPlayFilled
                color="#fff"
                size={20}
                className="transition-all hover:text-main"
              />
            ) : (
              <TbPlayerPauseFilled
                color="#fff"
                size={20}
                className="transition-all hover:text-main"
              />
            )}
          </button>
          <button onClick={() => handleSeek(currentTime + 10)} className="mr-5">
            <TbRewindForward10 size={20} color="#fff" />
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
};
