import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { get2faInfo, setTOTP } from "requests";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "components/shared/Button";
import { Input } from "components/shared/Input";
import { Loader } from "components/shared/Loader";
import { faCodeValidation } from "const";
import { ResponseError, WithPinProps } from "types";
import { useUserContext } from "context/user.context";
import { errorParser } from "utils";

interface TwoFactorAuthProps extends WithPinProps {
  toggle2FAModal: () => void;
}

export const TwoFactorAuth: FC<TwoFactorAuthProps> = ({
  toggle2FAModal,
  pin,
}) => {
  const queryClient = useQueryClient();
  const { user } = useUserContext();

  const { data, isLoading, error } = useQuery({
    queryFn: get2faInfo,
    enabled: !user?.["2fa"],
  });

  const {
    mutate: mutateTOTP,
    isLoading: isSettingTOTP,
    error: TOTPError,
  } = useMutation({
    mutationFn: setTOTP,
    onSuccess: (data) => {
      toggle2FAModal();
      queryClient.invalidateQueries("user", { exact: true });
    },
  });

  const {
    register: register2faForm,
    handleSubmit: handleSubmit2fa,
    formState: { errors },
  } = useForm({
    defaultValues: {
      totp: "",
      pin,
    },
    resolver: zodResolver(faCodeValidation),
  });

  const handle2faSubmit = (formValues: any) => {
    mutateTOTP({
      ...formValues,
      pin,
    });
  };

  if (isLoading) return <Loader />;

  function breakStringIntoParts(inputString: string, partLength: number = 4) {
    let result = [];
    for (let i = 0; i < inputString.length; i += partLength) {
      result.push(inputString.substring(i, i + partLength));
    }
    return result;
  }

  const renderConnect2FALayout = () => {
    return (
      <>
        <h3 className="text-lGrey text-base font-semibold mb-[6px]">
          Enable Two-Factor Auth
        </h3>
        <h5 className="text-grey text-xs">Make your safer with 3 easy steps</h5>
        <div className="mt-5">
          <div className="flex ">
            <div className="relative">
              <div className=" relative h-[24px] w-[24px] flex items-center justify-center border border-[#BABFC7] rounded-full bg-lightBlack z-10">
                <p className="text-lGrey font-semibold text-sm">1</p>
              </div>
              <div className="bg-[#BABFC7] h-full w-[1px] absolute left-1/2 -translate-x-1/2 top-0 "></div>
            </div>
            <div className="ml-[10px] pb-5">
              <h3 className="font-bold text-sm text-lGrey ">
                Download an Authenticator App
              </h3>
              <p className="text-sm text-[#BABFC7]">
                Download and install{" "}
                <a
                  className="text-main"
                  href="https://authy.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Authy
                </a>{" "}
                or{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                  target="_blank"
                  rel="noreferrer"
                  className="text-main"
                >
                  Google Authenticator
                </a>{" "}
                for your phone or tablet
              </p>
            </div>
          </div>

          <div className="flex ">
            <div className="relative">
              <div className=" relative h-[24px] w-[24px] flex items-center justify-center border border-[#BABFC7] rounded-full bg-lightBlack z-10">
                <p className="text-lGrey font-semibold text-sm">2</p>
              </div>
              <div className="bg-[#BABFC7] h-full w-[1px] absolute left-1/2 -translate-x-1/2 top-0" />
            </div>
            <div className="ml-[10px] pb-5">
              <h3 className="font-bold text-sm text-lGrey ">
                Scan the QR code
              </h3>
              <p className="text-sm text-[#BABFC7]">
                Open the authenticator app and scan the QR code image using your
                phones camera.
              </p>
              <div className="flex mt-4 items-center">
                <div className="h-[120px] w-[120px] p-[5px] rounded-md bg-white mr-5">
                  <img src={data.qrCodeUrl} alt={data.secret} />
                </div>
                <div className="w-[calc(100%-120px)]">
                  <h3 className="text-lGrey text-sm font-semibold mb-[6px] text-center">
                    2FA key (Manual entry)
                  </h3>
                  <div className="bg-lBlack border border-lightBorder w-full rounded-xl py-4 text-center text-sm text-lGrey">
                    {breakStringIntoParts(data.secret).map((string) => (
                      <span className="ml-4 first:ml-0">{string}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="relative">
              <div className=" relative h-[24px] w-[24px] flex items-center justify-center border border-[#BABFC7] rounded-full bg-lightBlack z-10">
                <p className="text-lGrey font-semibold text-sm">3</p>
              </div>
            </div>
            <div className="ml-[10px] pb-5 w-full">
              <h3 className="font-bold text-sm text-lGrey ">Use your code</h3>
              <p className="text-sm text-[#BABFC7]">
                Enter your generated 6-digit verification that you got in yor
                app.
              </p>
              <form
                className="mt-4"
                onSubmit={handleSubmit2fa(handle2faSubmit)}
              >
                <Input
                  register={register2faForm}
                  id="totp"
                  type="text"
                  placeholder="Enter authentication code"
                  className="bg-opacity-0 !w-full border-secondaryButton mb-2 "
                  errorMessage={errors.totp?.message}
                  label="2FA Code"
                />

                {TOTPError &&
                (TOTPError as ResponseError)?.response?.data.error ? (
                  <div className="text-center text-red mb-5">
                    {errorParser(TOTPError as ResponseError)}
                  </div>
                ) : null}
                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className="text-black text-sm font-semibold py-4 w-full bg-main rounded-xl"
                    disabled={isSettingTOTP}
                  >
                    Activate
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* <div className="h-[120px] w-[120px] mb-8 m-auto ">
            <img src={data.qrCodeUrl} alt={data.secret} />
          </div> */}
          {/* <div className="  rounded-md overflow-hidden">
            <div className="px-4 py-2 text-warning bg-warning bg-opacity-10">
              {data.secret}
            </div>
            <div className="px-4 py-2 text-warning bg-warning bg-opacity-30 text-sm tracking-tighter">
              If you're having trouble using the QR code, select manual entry on
              your app
            </div>
          </div> */}
        </div>
      </>
    );
  };

  return <div className="flex flex-col">{renderConnect2FALayout()}</div>;
};
