import { useQuery } from "react-query";

import { getReferralsHistory } from "requests";
import { Nullable } from "types";
import { useForm } from "react-hook-form";

import { FC } from "react";
import { ViewContent } from "components/shared/ViewContent";

interface TableRowProps {
  level: number;
  login: Nullable<string>;
  amount: string;
  email: string;
}

const TableRow: FC<TableRowProps> = ({ level, login, amount, email }) => {
  return (
    <div className="border-t py-[10px] border-lightBlack">
      <div className="grid grid-cols-4">
        <div className="">
          <div className="flex items-center">
            <p className="text-main  max-w-[70px] md:max-w-[180px] text-ellipsis overflow-hidden">
              {email ?? "-"}
            </p>
          </div>
        </div>
        <div className="text-lGrey font-semibold text-sm flex items-center">
          {login ?? "-"}
        </div>
        <div className="flex items-center text-white">${amount}</div>
        <div className="flex items-center justify-end pr-3 text-white">
          {level}
        </div>
      </div>
      {/* {currentOpenedInfo === ReferralStatusTableOpened.ACTIVATED && (
        <div className="py-[6px] px-4 border border-main bg-dMain rounded-xl mt-[10px]">
          {counterDataDividend.map((data) => (
            <CounterRowItem key={data.progress} {...data} />
          ))}
        </div>
      )}
      {currentOpenedInfo === ReferralStatusTableOpened.REFERRAL && (
        <div className="py-[6px] px-4 border border-main bg-dMain rounded-xl mt-[10px]">
          {counterDataReferral.map((data) => (
            <CounterRowItem key={data.amount} {...data} />
          ))}
        </div>
      )} */}
    </div>
  );
};

export const RefferralEarningsTable = () => {
  const { setValue, getValues } = useForm({
    defaultValues: {
      searchQuery: "",
    },
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["referralsEarnedHistory"],
    queryFn: getReferralsHistory,
  });

  // const handleSearchInputChange = (value: string | number) => {
  //   setValue("searchQuery", value as string);
  //   refetch();
  // };

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h3 className="text-lGrey font-semibold mb-5 md:mb-0">
          Affiliate structure{" "}
        </h3>
      </div>
      <div>
        <div className="grid grid-cols-4 pb-4">
          <div className="text-grey font-semibold text-xs">User</div>
          <div className="text-grey font-semibold text-xs">Login</div>
          <div className="text-grey font-semibold text-xs">Amount</div>
          <div className="text-grey font-semibold text-xs text-right pr-3">
            Level
          </div>
        </div>
        <div className="max-h-[536px] overflow-y-auto">
          <ViewContent
            isError={isError}
            isLoading={isLoading}
            data={data && data.data}
          >
            {(data: any) =>
              data.map((referralAccrual: any) => {
                return (
                  <TableRow
                    key={referralAccrual.id}
                    level={referralAccrual.level}
                    login={referralAccrual.login}
                    amount={referralAccrual.amount}
                    email={referralAccrual.email}
                  />
                );
              })
            }
          </ViewContent>
        </div>
      </div>
    </div>
  );
};
