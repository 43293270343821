import { FC } from "react";
import { Box } from "components/shared/Box";
import { useUserContext } from "context/user.context";
import { copyClipboard } from "utils";

import { Button } from "../Button";
import { useNavigate } from "react-router";
import { TelegramIcon } from "components/shared/Icons";

// TODO: move to env
const href = window.location.host.includes(".net")
  ? "https://pro-x.app"
  : "https://pro-x.app";

interface ReferralShareInformationProps {
  withButton?: boolean;
}

export const ReferralShareInformation: FC<ReferralShareInformationProps> = ({
  withButton = false,
}) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const invintationLink = `${href}?ref=${user?.referral_code}`;

  if (user && user?.referralType === "type3" && !user?.hasValidReferralType) {
    return (
      <Box className="bg-lightBlack border-none">
        <p className="text-center mb-5">
          Your affiliate program wasn't setup yet
        </p>
        <Button
          type="button"
          className="bg-main flex items-center justify-center max-w-[300px] m-auto"
          onClick={() => window.open("https://t.me/JulliAi")}
        >
          <span className="mr-2">Contact manager</span> <TelegramIcon />
        </Button>
      </Box>
    );
  }

  return (
    <Box className="bg-lightBlack border-none">
      {user?.referralType === null && !user?.hasValidReferralType ? (
        <div>
          <p>You haven't selected your affiliate program</p>
          <Button
            type="button"
            className="bg-main max-w-[300px] mt-4"
            onClick={() => {
              navigate("/affiliate/select-affiliate-program");
            }}
          >
            Select program
          </Button>
        </div>
      ) : (
        <>
          <h3 className="mb-4 text-lGrey font-semibold">Your partner link:</h3>
          <div className="flex flex-col md:flex-row">
            <div className="flex justify-between items-center rounded-[12px] bg-lBlack border border-boxBg p-4 w-full">
              <input
                disabled
                value={invintationLink}
                className="text-sm text-lGrey w-full pr-5 "
              />
              <div
                className="cursor-pointer"
                onClick={() => {
                  copyClipboard(invintationLink);
                }}
              >
                <img src="/icons/copy.svg" alt="copyIcon" />
              </div>
            </div>
            {/* TODO: figure out what exactly should be done with this button  */}
            {/* {withButton && (
              <Button
                type="button"
                className="border-main border bg-dMain md:max-w-[200px] mt-[10px] md:mt-0 md:ml-[10px] flex items-center justify-center"
              >
                <p className="text-main font-semibold text-sm mr-[10px]">
                  Share affiliate link
                </p>
                <ShareArrowIcon />
              </Button>
            )} */}
          </div>
        </>
      )}
    </Box>
  );
};
