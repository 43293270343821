import classNames from "classnames";
import { TwoFactorAuth } from "components/2FACode";
import { Box } from "components/shared/Box";
import { TwoFactorIcon } from "components/shared/Icons";
import { Modal } from "components/shared/Modal";
import { Portal } from "components/shared/Portal";
import { SecuredWithPin } from "components/shared/SecuredWithPin";
import { useUserContext } from "context/user.context";
import { useToggle } from "hooks";

export const TwoFactorSection = () => {
  const [is2FAModalOpen, toggle2FAModal] = useToggle();
  const { user } = useUserContext();

  return (
    <>
      <Box
        className={classNames(
          "bg-lightBlack mt-5",
          !user?.["2fa"] && "border-red"
        )}
      >
        <div className="flex items-center justify-between flex-col md:flex-row">
          <div className="flex">
            <TwoFactorIcon />
            <div className="flex flex-col ml-[10px]">
              <h4 className=" text-lGrey text-base font-semibold">
                Two-factor Authentication{" "}
                {!user?.["2fa"] ? (
                  <span className="text-red text-sm ml-[10px]">Disabled</span>
                ) : (
                  <span className="text-success text-sm ml-[10px]">
                    Enabled
                  </span>
                )}
              </h4>
              <p className="text-grey text-xs font-semibold">
                2FA is required, in order to secure your account
              </p>
            </div>
          </div>
          {!user?.["2fa"] && (
            <div className="mt-4 md:mt-0">
              <button
                type="button"
                className={classNames(
                  "bg-dMain border-main text-main rounded-xl border  w-[155px] py-[4px] disabled:border-none disabled:text-grey disabled:bg-boxBg"
                )}
                onClick={toggle2FAModal}
                disabled={!user?.pin}
              >
                {user?.pin ? "Set Up" : "PIN to Enabled 2FA"}
              </button>
            </div>
          )}
        </div>
      </Box>
      <Portal id="faModal">
        <Modal
          open={is2FAModalOpen}
          handleClose={toggle2FAModal}
          // className="max-w-[380px]"
        >
          <SecuredWithPin>
            <TwoFactorAuth toggle2FAModal={toggle2FAModal} />
          </SecuredWithPin>
        </Modal>
      </Portal>
    </>
  );
};
