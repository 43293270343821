import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "react-query";
import Cookie from "js-cookie";

import { login } from "requests";
import { LoginValidaionSchema, loginValidaion } from "const";

import { Input } from "components/shared/Input";
import { Button } from "components/shared/Button";
import { PuzzleCaptcha } from "components/PuzzleCaptcha";
import { SocialAuth } from "components/SocialAuth";
import { Auth, ResponseError } from "types";
import { errorParser } from "utils";

const Login = () => {
  const queryClient = useQueryClient();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaPassed, setCaptchaPassed] = useState(false);

  const { error, isError, isLoading, mutate } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      const { token, refresh_token } = data;

      if (data["2faToken"]) {
        Cookie.set(Auth.TOTP, data["2faToken"]);
        window.location.reload();
        return;
      }

      queryClient.invalidateQueries("recentSessions", { exact: true });

      // if no 2fa is enabled on account
      Cookie.set(Auth.TOKEN, token);
      Cookie.set(Auth.REFRESH, refresh_token);
      window.location.reload();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<LoginValidaionSchema>({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: zodResolver(loginValidaion),
  });

  const onSubmit = async (formValues: LoginValidaionSchema) => {
    mutate(formValues);
  };

  const navigate = useNavigate();

  const handleRedirectToLogin = () => {
    navigate("/auth/sign-up");
  };

  return (
    <div className="w-[320px] md:w-auto">
      <a
        href="https://pro-x.app/"
        rel="noreferrer"
        target="_blank"
        className="text-white text-3xl text-center block mx-auto mb-10"
      >
        <span className="text-main">PRO</span>X
      </a>

      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Journey Today
      </h3>
      <div className="[&>*]:mt-4">
        <SocialAuth />
      </div>
      <div className="text-white text-center my-4">or</div>
      <form
        className="m-auto max-w-[360px] mb-2 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          placeholder="Email"
          id="username"
          register={register}
          className="h-[38px] mb-4 rounded-md px-4 w-full"
          errorMessage={errors.username?.message}
        />
        <Input
          type="password"
          placeholder="Password"
          id="password"
          register={register}
          className="h-[38px] rounded-md px-4 mb-4"
          errorMessage={errors.password?.message}
        />

        {isError && (error as ResponseError)?.response?.data.error && (
          <div className="text-center text-red mt-5">
            {errorParser(error as ResponseError)}
          </div>
        )}
        <Button
          // if captcha is being shown and not passed, lock submit button
          disabled={!captchaPassed && showCaptcha}
          type="submit"
          text="Log in"
          className="bg-main md:w-[360px]  py-0 mt-[57px] font-bold"
          isLoading={isLoading}
        />
      </form>
      <div className="flex justify-center text-white">
        <Link to="/auth/forgot-password" className="text-lightGrey">
          Forgot password?
        </Link>
      </div>
      <div className="flex justify-center flex-col mt-[80px]">
        <div className="text-center text-white">Don't have an account?</div>
        <Button
          onClick={handleRedirectToLogin}
          type="button"
          className="bg-main font-bold text-black mt-4 text-sm hover:shadow-buttonShadow"
          text="Sign Up"
        />
      </div>
    </div>
  );
};

export default Login;
