import { TelegramIcon } from "components/shared/Icons";
import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";
import { selectReferralProgram } from "requests";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useUserContext } from "context/user.context";
import classNames from "classnames";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Breadcrumbs } from "components/shared/Breadcrumbs";

const breadcrumbsAffiliate = [
  {
    title: "Affiliate",
    href: "/affiliate",
  },
  {
    title: `Select program`,
    href: `/affiliate/withdraw`,
  },
];

const SelectAffiliateProgram = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUserContext();

  const { mutate: mutateSelectProgram, isLoading: isSelecting } = useMutation({
    mutationFn: selectReferralProgram,
    onSuccess: (data) => {
      toast.success("Referral selected successfuly");
      queryClient.invalidateQueries("user", { exact: true });
    },
  });

  const handleSelectClick = (isContact: boolean, type?: number) => {
    if (isContact) {
      window.open("https://t.me/JulliAi");
    } else {
      mutateSelectProgram({
        referralType: `type${type}`,
      });
    }
  };

  useEffect(() => {
    if (user?.referralType) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      <Breadcrumbs links={breadcrumbsAffiliate} />
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        <Box
          className={classNames(
            "col-span-1 flex flex-col md:col-span-3 md:flex-row border-none "
          )}
        >
          <p className="text-white text-center max-w-[768px] m-auto">
            Join our revenue-sharing affiliate program and earn from every
            course sale! We offer a generous 50% commission on each sale.
            Imagine the potential income you’re missing out on by not taking
            part in it. Your friends are purchasing courses, and you could be
            profiting from it. Don’t miss out—start earning today!
          </p>
        </Box>
        <Box
          className={classNames(
            "col-span-1 flex flex-col md:col-span-1 bg-lightBlack transition-all text-white",
            user?.referralType && "border-main border-2"
          )}
        >
          <h2 className="text-2xl">Influencer</h2>
          <ul className="mt-4">
            <li>40% from each direct sale.</li>
            <li>10% from sales made by your partners.</li>
          </ul>
          <Button
            type="button"
            className="bg-main mt-8"
            onClick={() => handleSelectClick(false, 1)}
            disabled={isSelecting || !!user!.referralType}
          >
            {user?.referralType ? "Selected" : "Select"}
          </Button>
        </Box>
        <Box className="col-span-1 flex flex-col md:col-span-1 bg-lightBlack transition-all  text-white">
          <h2 className="text-2xl">Partner Community</h2>
          <ul className="mt-4">
            <li>10% from each level.</li>
            <li>5 levels deep.</li>
          </ul>
          <Button
            type="button"
            className="bg-main mt-8"
            onClick={() => handleSelectClick(false, 1)}
            disabled={isSelecting || !!user!.referralType}
          >
            {user?.referralType ? "Selected" : "Select"}
          </Button>
        </Box>
        <Box className="col-span-1 flex flex-col md:col-span-1 bg-lightBlack transition-all  text-white">
          <h2 className="text-2xl">Individual</h2>
          <ul className="mt-4">
            <li>For active leaders.</li>
            <li>Personalized conditions are selected.</li>
          </ul>
          <Button
            type="button"
            className="bg-main mt-8 flex items-center justify-center"
            onClick={() => handleSelectClick(true)}
            disabled={isSelecting}
          >
            <span className="mr-2">Contact manager</span> <TelegramIcon />
          </Button>
        </Box>
      </div>
    </>
  );
};

export default SelectAffiliateProgram;
