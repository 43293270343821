import classNames from "classnames";
import SliderCaptcha, { VerifyParam } from "rc-slider-captcha";
import { FC, useLayoutEffect, useState } from "react";
import { generateRandomNumber } from "utils";

interface PuzzleCaptchaProps {
  bgUrl?: string;
  puzzleUrl?: string;
  onVerifyCallback: () => void;
}

const puzzleVariants = [
  {
    bgUrl: "/images/puzzleOne.png",
    puzzleUrl: "/images/puzzlePartOne.png",
    min: 150,
    max: 160,
    className: "puzzleOne",
  },
  {
    bgUrl: "/images/puzzleTwo.png",
    puzzleUrl: "/images/puzzlePartTwo.png",
    min: 25,
    max: 35,
    className: "puzzleTwo",
  },
  {
    bgUrl: "/images/puzzleThree.png",
    puzzleUrl: "/images/puzzlePartThree.png",
    min: 73,
    max: 80,
    className: "puzzleThree",
  },
  {
    bgUrl: "/images/puzzleFour.png",
    puzzleUrl: "/images/puzzlePartFour.png",
    min: 73,
    max: 80,
    className: "puzzleFour",
  },
];

// TODO: make randrom shulffle of puzzle view 3 - 5 visions
export const PuzzleCaptcha: FC<PuzzleCaptchaProps> = ({
  bgUrl = "/images/puzzleOne.png",
  puzzleUrl = "/images/puzzlePartOne.png",
  onVerifyCallback,
}) => {
  const [puzzleNumber, setPuzzleNumber] = useState(
    generateRandomNumber(1, 4) - 1
  );

  const getPuzzleIndex = () =>
    localStorage.getItem("index")
      ? JSON.parse(localStorage.getItem("index") as string)
      : puzzleNumber;

  useLayoutEffect(() => {
    localStorage.removeItem("index");
  }, []);

  const puzzleData = puzzleVariants[puzzleNumber];
  const classNameFromPuzzleConfig = puzzleData.className;

  const handleOnVerify = async (data: VerifyParam) => {
    const dataToCompare = puzzleVariants[getPuzzleIndex()];

    if (data.x >= dataToCompare.min && data.x <= dataToCompare.max) {
      // callback which triggers response
      onVerifyCallback();
      return Promise.resolve();
    } else {
      const puzzleIndex = generateRandomNumber(1, 4) - 1;
      setPuzzleNumber(puzzleIndex);
      localStorage.setItem("index", `${puzzleIndex}`);

      return Promise.reject();
    }
  };

  const handleVerify = async () => {
    const index = getPuzzleIndex();

    return {
      ...puzzleVariants[index],
    };
  };

  return (
    <SliderCaptcha
      className={classNames(classNameFromPuzzleConfig)}
      style={{
        margin: "auto",
      }}
      puzzleSize={{
        width: 60,
        height: 54,
      }}
      errorHoldDuration={500}
      tipText={{
        default: <h2>Slide to complete</h2>,
        loading: <h2>Loading...</h2>,
        error: <h2>Try again</h2>,
      }}
      bgSize={{
        width: 240,
      }}
      showRefreshIcon={false}
      request={handleVerify}
      onVerify={handleOnVerify}
    />
  );
};
