import { ReferralShareInformation } from "components/shared/ReferralShareInfo";
import { ReferralTree } from "components/ReferralTree";
import { RefferralEarningsTable } from "components/ReferralTableHistory";

import { ReferralBonuses } from "components/ReferralBonuses";
import { Box } from "components/shared/Box";
import { ReferralInfoBlock } from "components/ReferralsInfoBlock";
import { ReferralSeniorPartner } from "components/ReferralSeniorPartner";
import Area from "components/Area";
import { useQuery } from "react-query";
// getActiveReferralStats,
import { getReferralStats } from "requests";
import { ViewContent } from "components/shared/ViewContent";
// import { EmptyData } from "components/shared/EmptyData";
import { useUserContext } from "context/user.context";
import { EmptyData } from "components/shared/EmptyData";

const ReferallPageStats = () => {
  const { user } = useUserContext();

  const handleSelectChartRange = (value: any) => {
    console.log(value, "value");
  };

  const {
    data: referralStats,
    isLoading: isReferralStatsLoading,
    isError,
  } = useQuery({
    queryKey: "referralStats",
    queryFn: getReferralStats,
  });

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
      <Box className="col-span-1 flex flex-col md:col-span-2 md:flex-row">
        <div className="mb-5 md:mb-0 md:mr-5">
          <ReferralBonuses />
        </div>
        <div className="w-full">
          <ViewContent
            // isLoading={isReferralStatsLoading || isActiveReferralStatsLoading}
            isLoading={isReferralStatsLoading}
            // isError={isError || isActiveStatsError}
            isError={isError}
            data={[referralStats]}
          >
            {(data: any) => {
              if (data.length <= 0 || data[0].length <= 0) return <EmptyData />;

              const propsData = data.map((stats: any, i: number) => ({
                label: i === 0 ? "Partners" : "Active partners",
                data: stats.map((stat: any) => ({
                  primary: new Date(stat.date),
                  secondary:
                    i === 0 ? stat.referralsCount : stat.activeReferralsCount,
                  radius: undefined,
                })),
              }));

              return (
                <Area
                  data={propsData}
                  onSelectRange={handleSelectChartRange}
                  chartContainerClassname="h-[208px]"
                  // @ts-expect-error
                  primaryAxisProps={{ show: false }}
                />
              );
            }}
          </ViewContent>
        </div>
      </Box>
      <Box className="col-span-1 md:col-span-1">
        <ReferralInfoBlock />
      </Box>
      <Box className="col-span-1 border-none !p-0 md:col-span-2">
        <ReferralShareInformation withButton />
      </Box>
      {(user?.referred_by ||
        user?.referred_by_email ||
        user?.referred_by_telegram) && (
        <Box className="col-span-1 md:col-span-1">
          <ReferralSeniorPartner />
        </Box>
      )}
      <Box className="col-span-1 md:col-span-1">
        <ReferralTree />
      </Box>
      <Box className="col-span-1 md:col-span-2">
        <RefferralEarningsTable />
      </Box>
    </div>
  );
};

export default ReferallPageStats;
