import { Routes, Route } from "react-router-dom";

import AuthLayout from "layouts/auth.layout";
import { useAuth } from "hooks";
import { UserContextProvider } from "context/user.context";
import { ConfettiContextProvider } from "context/confetti.context";

import { ROUTES } from "config/routes";
import AppLayout from "layouts/app.layout";
import Settings from "pages/settings/Profile.page";
import { ProtectedRoute } from "components/shared/ProtectedRoutes";
import Login from "pages/auth/Login.page";
import SignUp from "pages/auth/SignUp.page";
import SignUpStepOne from "pages/auth/SignUpStepOne.page";
import SignUpStepTwo from "pages/auth/SignUpStepTwo.page";
import ForgotPasswordStepOne from "pages/auth/ForgotPasswordStepOne.page";
import ForgotPasswordStepTwo from "pages/auth/ForgotPasswordStepTwo.page";
import ReferallPage from "pages/referral/Referral.page";
import SelectAffiliateProgram from "pages/referral/SelectAffiliateProgram.page";
import HomePage from "pages/Home.page";
import { Courses } from "pages/Courses/Courses.page";
import { CourseDetails } from "pages/Courses/CourseDetails.page";
import { Withdraw } from "pages/finance/Withdraw.page";
import TermsOfServices from "pages/TermsOfServices.page";

export const RootRouter = () => {
  const { token, TwoFactToken } = useAuth();
  const isAuthorized = !!token;
  const has2faEnabled = !!TwoFactToken;

  return (
    <Routes>
      <Route
        path={ROUTES.auth}
        element={
          <ProtectedRoute
            isAllowed={!has2faEnabled && !isAuthorized}
            redirectPath={"/"}
          >
            <AuthLayout />
          </ProtectedRoute>
        }
      >
        <Route path="login" element={<Login />} />
        {/* could be refactored */}
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-up/step-one" element={<SignUpStepOne />} />
        <Route path="sign-up/step-two" element={<SignUpStepTwo />} />
        <Route path="forgot-password">
          <Route index element={<ForgotPasswordStepOne />} />
          <Route path=":code" element={<ForgotPasswordStepTwo />} />
        </Route>
        <Route path="*" element={<div>Not found</div>} />
      </Route>

      <Route path="/terms-of-services" element={<TermsOfServices />} />
      <Route
        element={
          <ProtectedRoute isAllowed={isAuthorized || has2faEnabled}>
            <ConfettiContextProvider>
              <UserContextProvider>
                <AppLayout />
              </UserContextProvider>
            </ConfettiContextProvider>
          </ProtectedRoute>
        }
      >
        <Route path={ROUTES.home}>
          <Route index element={<HomePage />} />
          <Route path="settings">
            <Route index element={<Settings />} />
            <Route path="*" element={<div>not found</div>}></Route>
          </Route>
          <Route path="courses">
            <Route index element={<Courses />} />
            <Route path=":courseId" element={<CourseDetails />} />
            <Route path="*" element={<div>404 not found page</div>}></Route>
          </Route>
          <Route path="affiliate">
            <Route index element={<ReferallPage />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route
              path="select-affiliate-program"
              element={<SelectAffiliateProgram />}
            />
            <Route path="*" element={<div>404 not found page</div>}></Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<div>404 not found page</div>} />
    </Routes>
  );
};
