import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Breadcrumb {
  title: string;
  href: string;
}

interface BreadcrumbsProps {
  links: Breadcrumb[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ links }) => {
  const withoutLast = links.slice(0, links.length - 1);
  const activeElement = links.slice(-1);

  return (
    <>
      <ul className="flex mb-5 mt-5 md:mt-0">
        {withoutLast.map((link, i) => {
          return (
            <li key={link.title} className="">
              <Link to={link.href} className="hover:text-main transition-all">
                {link.title}
              </Link>
              <span className="mx-2">/</span>
            </li>
          );
        })}
        <li key={"lastElement"} className="breadcrumb-item active">
          {activeElement[0].title}
        </li>
      </ul>
    </>
  );
};
