import { Outlet } from "react-router-dom";

function AuthLayout() {
  return (
    <div className="flex justify-center items-center">
      <div className="max-w-[50%] w-full hidden lg:flex flex-col items-center justify-center bg-lightBlack min-h-[100vh] self-stretch ">
        <a
          href="https://pro-x.app/"
          rel="noreferrer"
          target="_blank"
          className="text-white text-5xl"
        >
          <span className="text-main">PRO</span>X
        </a>
        <div className="text-center text-white mt-[74px]">
          <h1 className="max-w-xl text-[28px] mb-5 leading-9">
            <span className="text-main">PRO</span>X - Exceptional financial
            results.
          </h1>

          <p className="max-w-[375px] m-auto text-sm">
            Join and start your path to success with{" "}
            <span className="text-main">PRO</span>X today! Become a part of a
            community where success and inspiration meet at every step.
          </p>
        </div>
      </div>
      <div className="lg:max-w-[50%] min-w-[350px] w-full min-h-[100vh] bg-black flex items-center justify-center py-5 self-stretch">
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
