import apiInstance from "api";

export const getCoursesPurchased = () =>
  apiInstance.get("/courses/purchased?languageId=1").then((res) => res.data);

export const getCoursesNotPurchased = () =>
  apiInstance
    .get("/courses/not-purchased?languageId=1")
    .then((res) => res.data);

export const getCourses = () =>
  apiInstance.get("/courses?languageId=1").then((res) => res.data);

export const getSingleCourse = ({ queryKey }: any) => {
  const [_, id, lngId] = queryKey;

  return apiInstance
    .get(`/courses/${id}?languageId=${lngId}`)
    .then((res) => res.data);
};

export const getSingleLesson = ({ queryKey }: any) => {
  const [_, id, lngId] = queryKey;

  return apiInstance
    .get(`/lessons/course/${id}?languageId=${lngId}`)
    .then((res) => res.data);
};

export const buyCourse = (requestBody: any) =>
  apiInstance
    .post(`/purchase/course/${requestBody.courseId}`, requestBody)
    .then((res) => res.data);

export const getNextLesson = ({ queryKey }: any) => {
  const [_, courseId] = queryKey;

  return apiInstance
    .get(`/lessons/course/${courseId}/next`)
    .then((res) => res.data);
};

export const getLanguages = () =>
  apiInstance.get("/languages").then((res) => res.data);
