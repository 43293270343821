import { Outlet } from "react-router-dom";
import classNames from "classnames";

import { useAuth, useToggle, useWindowResize } from "hooks";
import { menuItems } from "const";

import FACheck from "pages/2faSecurity.page";
import { SidebarToggleItem } from "components/SidebarToggleItem";
import { Navbar } from "components/Navbar";
import { Loader } from "components/shared/Loader";
import { useUserContext } from "context/user.context";
import MobileMenu from "components/MobileMenu";
import ReactConfetti from "react-confetti";
import { useConfettiContext } from "context/confetti.context";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { cancelTransaction } from "requests";

const AppLayout = () => {
  const { height, width } = useWindowResize();
  const { handleSignout, TwoFactToken, token } = useAuth();
  const { isProfileLoading, profileError, user } = useUserContext();
  const { showConfetti, setShowConfetti } = useConfettiContext();
  const [showSidebar, toggleSidebar, setSidebarView] = useToggle();
  const queryClient = useQueryClient();

  const { mutate: mutateCancelTransaction, isLoading: isCancelling } =
    useMutation({
      mutationFn: cancelTransaction,
      onSuccess: (data) => {
        queryClient.invalidateQueries("courses", { exact: true });
        toast.success("Course purchase was cancelled");
        // setShowConfetti(true);
      },
      onError: () => {
        toast.error("Error while purchasing course");
      },
    });

  const handleCloseSidebar = () => setSidebarView(false);

  useEffect(() => {
    if (window.location.search.includes("cancel")) {
      mutateCancelTransaction(window.location.search.split("id=")[1]);
      toast.error("Payment cancelled");
    }
  }, []);

  if (isProfileLoading)
    return (
      <div className="flex min-h-screen bg-mainBg">
        <Loader className="!bg-white" />
      </div>
    );

  if (profileError) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-mainBg">
        <h2 className="text-white">Something went wrong while loading</h2>
      </div>
    );
  }

  if (TwoFactToken && !token) {
    return <FACheck />;
  }

  return (
    <div className="relative flex overflow-auto lg:min-h-screen">
      {showConfetti && (
        <ReactConfetti
          width={width}
          height={height}
          recycle={false}
          onConfettiComplete={() => {
            setShowConfetti(false);
          }}
        />
      )}

      <div
        className={classNames(
          "fixed z-[49] h-full w-full bg-black bg-opacity-60 transition-all",
          showSidebar ? "block" : "hidden"
        )}
        onClick={toggleSidebar}
      />
      <aside
        className={classNames(
          "fixed z-50 h-full w-full max-w-[200px] self-stretch overflow-y-auto bg-sidebar py-5 transition-all xl:relative xl:left-0 xl:h-auto",
          showSidebar ? "left-0" : "-left-full"
        )}
      >
        <div className="relative mb-5 xl:mb-0">
          <div>
            <a
              href="https://pro-x.app/"
              rel="noreferrer"
              target="_blank"
              className="text-white text-3xl text-center block mx-auto mb-10"
            >
              <span className="text-main">PRO</span>X
            </a>
          </div>
        </div>
        <div className="my-[30px] hidden flex-col p-5 xl:flex">
          <img
            className="mb-[30px] h-[60px] w-[60px] rounded-[20px] border border-lightBlack object-cover"
            src={user?.avatar ?? "/images/defaultUserAvatar.jpeg"}
            alt={user?.firstName}
          />
          <h2 className="mb-[6px] text-sm text-white">
            Hello, {user?.firstName}
          </h2>
          <p className="text-xs font-semibold text-grey text-ellipsis overflow-hidden">
            {user?.email}
          </p>
        </div>

        <ul>
          {menuItems.map((item) => (
            <SidebarToggleItem
              key={item.title}
              item={item}
              toggleSidebar={handleCloseSidebar}
            />
          ))}
        </ul>

        <button
          className="mt-5 ml-5 text-grey text-sm"
          type="button"
          onClick={handleSignout}
        >
          Logout
        </button>
      </aside>
      <main className="mb-24 flex w-full flex-col self-stretch bg-mainBg px-[10px] text-defaultText lg:px-[30px] xl:mb-5 xl:w-[calc(100%-200px)]">
        <Navbar />

        <Outlet />
      </main>
      <MobileMenu />
    </div>
  );
};

export default AppLayout;
