import { OverallBalance } from "components/OverallBalance";
import { ReferralIncome } from "components/ReferralIncome";
import { TopReferrals } from "components/TopReferrals";
import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";
import { TelegramIcon } from "components/shared/Icons";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
      <Box className="col-span-1 md:col-span-3">
        <OverallBalance />
      </Box>
      <Box className="col-span-1 md:col-span-1">
        <h3 className="text-main text-center mt-5 font-bold mb-5">NEW EVENT</h3>
        <Link
          to={`https://t.me/+Rey2MW_5fcRmNzJi`}
          target="_blank"
          className="text-main"
        >
          <img
            className="h-[150px] w-full object-contain"
            src={`/images/event-preview.png`}
            alt=""
          />
        </Link>
        <h3 className="text-white text-center mt-5">
          $15k income insights Pro
        </h3>

        <Button
          type="button"
          className="bg-main mt-8 flex items-center justify-center"
          onClick={() =>
            window.open(`https://t.me/+Rey2MW_5fcRmNzJi`, "_blank")
          }
        >
          <span className="mr-2">Join</span> <TelegramIcon />
        </Button>
      </Box>
      <Box className="col-span-1 md:col-span-2">
        <ReferralIncome />
      </Box>
      <Box className="col-span-1 md:col-span-2">
        <TopReferrals />
      </Box>
    </div>
  );
};

export default Home;
