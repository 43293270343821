import { Nullable, Option } from "./shared.type";

export enum GenderTypes {
  MALE = 1,
  FEMALE = 2,
}

export interface UserProps {
  email: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  phone: string;
  sex: string;
  "2fa": boolean;
  pin: boolean;
  payId: string;
  telegram: string;
  gender: GenderTypes | Option;
  fiat: boolean;
  referral_code: string;
  referred_by: null | any;
  activeReferralsCount: number;
  referralsCount: number;
  activeReferralsPercentage: string;
  login: string;
  referralType: Nullable<string>;
  hasValidReferralType: boolean;
  referred_by_email: null;
  referred_by_telegram: null;
}

export interface UpdateUserProps {
  firstName: string;
  lastName: string;
  phone: string;
  telegram: string;
  gender: GenderTypes;
}

export interface PartnterInfo {
  username: string;
  email: string;
  level: number;
  payId: number;
}
