import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

import { Button } from "components/shared/Button";
import { Input } from "components/shared/Input";
import { PincodeValidationSchema, pincodeValidation } from "const";

import { setPin } from "requests";

interface TwoFactorAuthProps {
  togglePinModal: () => void;
}

export const PinCodeForm: FC<TwoFactorAuthProps> = ({ togglePinModal }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: setPin,
    onSuccess: (data) => {
      togglePinModal();
      toast(data.message);
      queryClient.invalidateQueries("user", { exact: true });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PincodeValidationSchema>({
    defaultValues: {
      pin: "",
      pin_confirmation: "",
    },
    resolver: zodResolver(pincodeValidation),
  });

  const onSubmit = (formValues: PincodeValidationSchema) => {
    mutate(formValues);
  };

  return (
    <div className="w-[320px] md:w-[380px] m-auto">
      <h3 className="text-lGrey text-base font-semibold mb-[6px]">
        Add pincode to your App
      </h3>
      <div>
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <Input
            register={register}
            id="pin"
            type="password"
            placeholder="Enter pin code"
            className="bg-opacity-0  mb-4"
            errorMessage={errors.pin?.message}
            label="Set your PIN code"
          />
          <Input
            register={register}
            id="pin_confirmation"
            type="password"
            placeholder="Confirm your pin"
            className="bg-opacity-0 "
            errorMessage={errors.pin_confirmation?.message}
            label="Confirm your new pin"
          />
          <div className="mt-7 flex justify-end">
            <Button
              text="Continue"
              type="submit"
              className="bg-main text-sm  font-semibold text-black"
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
